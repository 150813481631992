import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import '../../constants/global.css'
import './style/footerLogin.css'
import ButtonCleanGray from '../atoms/ButtonCleanGray';
import Icon1 from '../../images/Icon1.png'
import Icon2 from '../../images/Icon2.png'
import Icon3 from '../../images/Icon3.png'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FOOTER.ALL.PRODUCTS-LIST-TITLE" : "PRODUITS",
    "FOOTER.ALL.PRODUCTS-LIST-FIRST-ITEM" :	"NOS DERNIERS PRODUITS",
    "FOOTER.ALL.PRODUCTS-LIST-SECOND-ITEM" : "E.6000 - Capteurs d'étalonnage du couple",
    "FOOTER.ALL.PRODUCTS-LIST-THIRD-ITEM" :	"CD.12A - Banc d'étalonnage du couple",
    "FOOTER.ALL.PRODUCTS-LIST-FOURTH-ITEM" :	"779.CML - Lampe d’inspection carrosserie",
    "FOOTER.ALL.PRODUCTS-LIST-FIVE-ITEM" :	"779.UH - Rampe d'éclairage rechargeable",
    "FOOTER.ALL.SOLUTIONS-LIST-TITLE" :	"SOLUTIONS",
    "FOOTER.ALL.SOLUTIONS-LIST-FIRST-ITEM" :	"TOUTES NOS SOLUTIONS",
    "FOOTER.ALL.SOLUTIONS-LIST-SECOND-ITEM" :	"ToughSystem - Système de rangement mobile",
    "FOOTER.ALL.SOLUTIONS-LIST-THIRD-ITEM" :	"Serrage contrôlé - Toute la dynamométrie",
    "FOOTER.ALL.SOLUTIONS-LIST-FOURTH-ITEM" :	"SLS - Le travail en hauteur",
    "FOOTER.ALL.SOLUTIONS-LIST-FIVE-ITEM" :	"MBOX - Coffrets de douilles",
    "FOOTER.ALL.DOWNLOAD-LIST-TITLE" :	"TÉLÉCHARGEMENT",
    "FOOTER.ALL.DOWNLOAD-LIST-FIRST-ITEM" :	"Brochures",
    "FOOTER.ALL.DOWNLOAD-LIST-SECOND-ITEM" :	"Notices d'utilisation",
    "FOOTER.ALL.DOWNLOAD-LIST-THIRD-ITEM" :	"Configurateur JetLine +",
    "FOOTER.ALL.CONTACT-LIST-TITLE" :	"CONTACT",
    "FOOTER.ALL.CONTACT-LIST-FIRST-ITEM" :	"Carrières",
    "FOOTER.ALL.CONTACT-LIST-SECOND-ITEM" :	"Salle de Presse",
    "FOOTER.ALL.CONTACT-LIST-THIRD-ITEM" :	"Plan d'accès",
    "FOOTER.ALL.CONTACT-LIST-FOURTH-ITEM" :	"Renseignements Techniques",
    "FOOTER.ALL.BUTTON-WORLD-TITLE" :	"FACOM",
    "FOOTER.ALL.BUTTON-WORLD-SUB-TITLE" :	"DANS LE MONDE",
    "FOOTER.ALL.BUTTON-DISTRIBUTER-TITLE" :	"TROUVER UN",
    "FOOTER.ALL.BUTTON-DISTRIBUTER-SUB-TITLE" :	"Distributeur",
    "FOOTER.ALL.BUTTON-SALES-TITLE" :	"SERVICE",
    "FOOTER.ALL.BUTTON-SALES-SUB-TITLE" :	"APRÉS VENTE",

}
function FooterLogin(){

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    return (
        <div className="py-5 px-4 Background_Gray2">
            <div className="row">
                <div className="col-0 col-lg-2"></div>
                <div className="col-0 col-md-2 pt-5 mx-3">
                    <h3 className="h3-stl-footer">{content['FOOTER.ALL.PRODUCTS-LIST-TITLE']}</h3>
                    <a href="https://www.facom.fr/produits/Nos-derniers-produits.html" target="_blank">{content['FOOTER.ALL.PRODUCTS-LIST-FIRST-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/produits/Capteurs-d'etalonnage-du-couple-Description.html" target="_blank">{content['FOOTER.ALL.PRODUCTS-LIST-SECOND-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/produits/Banc-d'etalonnage-du-couple-Description.html" target="_blank">{content['FOOTER.ALL.PRODUCTS-LIST-THIRD-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/produits/779-CML-description.html" target="_blank">{content['FOOTER.ALL.PRODUCTS-LIST-FOURTH-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/produits/779-UH-Description.html" target="_blank">{content['FOOTER.ALL.PRODUCTS-LIST-FIVE-ITEM']}</a>
                    

                    <h3 className="h3-stl-footer mt-5">{content['FOOTER.ALL.SOLUTIONS-LIST-TITLE']}</h3>
                    <a href="https://www.facom.fr/solutions/Solutions.html" target="_blank">{content['FOOTER.ALL.SOLUTIONS-LIST-FIRST-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/produits/ToughSystem.html" target="_blank">{content['FOOTER.ALL.SOLUTIONS-LIST-SECOND-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/solutions/Dynamometrie-FACOM.html" target="_blank">{content['FOOTER.ALL.SOLUTIONS-LIST-THIRD-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/solutions/SLS-Le-travail-en-hauteur.html" target="_blank">{content['FOOTER.ALL.SOLUTIONS-LIST-FOURTH-ITEM']}</a>
                    <br/>
                    <a href="https://catalogue.facom.com/mbox/fr/" target="_blank">{content['FOOTER.ALL.SOLUTIONS-LIST-FIVE-ITEM']}</a>
                </div>
                <div className="col-0 col-md-2 pt-5 mx-3">
                    <h3 className="h3-stl-footer">{content['FOOTER.ALL.DOWNLOAD-LIST-TITLE']}</h3>
                    <a href="https://www.facom.fr/services/brochures/Brochures-et-catalogue.html" target="_blank">{content['FOOTER.ALL.DOWNLOAD-LIST-FIRST-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/services/notices-d'utilisations/Notices-d'utilisations.html" target="_blank">{content['FOOTER.ALL.DOWNLOAD-LIST-SECOND-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/services/configurateur-3d-jetline-plus/Configurateur-3D-JetLine-Plus.html" target="_blank">{content['FOOTER.ALL.DOWNLOAD-LIST-THIRD-ITEM']}</a>

                    <h3 className="h3-stl-footer mt-5">{content['FOOTER.ALL.CONTACT-LIST-TITLE']}</h3>
                    <a href="https://www.facom.fr/contact/carrieres/Espace-Carrieres.html"target="_blank">{content['FOOTER.ALL.CONTACT-LIST-FIRST-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/contact/salle-de-presse/Salle-de-Presse.html" target="_blank">{content['FOOTER.ALL.CONTACT-LIST-SECOND-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/Plan-d'acces-FACOM.html" target="_blank">{content['FOOTER.ALL.CONTACT-LIST-THIRD-ITEM']}</a>
                    <br/>
                    <a href="https://www.facom.fr/contact/contactez-nous/Renseignements-Techniques.html" target="_blank">{content['FOOTER.ALL.CONTACT-LIST-FOURTH-ITEM']}</a>
                
                </div>
                <div className="col-0 col-md-1"></div>
                <div className="col-0 col-md-3 pt-5 mx-3" >
                    <div style={{display:'flex'}}>
                        <ButtonCleanGray  
                            message = {
                                <div className="row" style={{width:'100%'}}>
                                    <div className="col-4" style={{paddingRight:'0', marginRight:'0'}}>
                                        <img src={Icon1} style={{paddingBottom:'10px',paddingTop:'10px',width:'80%'}}></img>
                                    </div>
                                    <div className="col-8">
                                        <h3 className="h4-stl-footer-btn" style={{paddingTop:'10px'}}>{content['FOOTER.ALL.BUTTON-WORLD-TITLE']}</h3>
                                        <h3 className="h3-stl-footer-btn" style={{width:'190px'}}>{content['FOOTER.ALL.BUTTON-WORLD-SUB-TITLE']}</h3>
                                    </div>
                                </div>
                            }
                            reference="https://www.facom.com/?_ga=2.258111626.1235616950.1605607184-912071394.1605607184"></ButtonCleanGray>
                    </div>
                    <div className="pt-1"></div>
                    <div style={{display:'flex'}}>
                        <ButtonCleanGray  
                            message = {
                                <div className="row" style={{width:'100%'}}>
                                    <div className="col-4" style={{paddingRight:'0', marginRight:'0'}}>
                                        <img src={Icon2} style={{paddingBottom:'10px',paddingTop:'10px',width:'80%'}}></img>
                                    </div>
                                    <div className="col-8">
                                        <h3 className="h4-stl-footer-btn" style={{paddingTop:'10px'}}>{content['FOOTER.ALL.BUTTON-DISTRIBUTER-TITLE']}</h3>
                                        <h3 className="h3-stl-footer-btn" style={{width:'190px'}}>{content['FOOTER.ALL.BUTTON-DISTRIBUTER-SUB-TITLE']}</h3>
                                    </div>
                                </div>
                            }
                            reference="https://www.facom.fr/services/points-de-ventes/Points-de-ventes.html"></ButtonCleanGray>
                    </div>
                    <div className="pt-1"></div>
                    <div style={{display:'flex'}}>
                        <ButtonCleanGray  
                            message = {
                                <div className="row" style={{width:'100%'}}>
                                    <div className="col-4" style={{paddingRight:'0', marginRight:'0'}}>
                                        <img src={Icon3} style={{paddingBottom:'10px',paddingTop:'10px',width:'80%'}}></img>
                                    </div>
                                    <div className="col-8">
                                        <h3 className="h4-stl-footer-btn" style={{paddingTop:'10px'}}>{content['FOOTER.ALL.BUTTON-SALES-TITLE']}</h3>
                                        <h3 className="h3-stl-footer-btn" style={{width:'190px'}}>{content['FOOTER.ALL.BUTTON-SALES-SUB-TITLE']}</h3>
                                    </div>
                                </div>
                            }
                            reference="https://www.facom.fr/services/service-apres-vente/service-apres-ventes.html"></ButtonCleanGray>
                    </div>
                    
                </div>
                <div className="py-4">
                    
                </div>
                
            </div>
        </div>

        );
};

export default FooterLogin;