import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'

// COMPONENTS
import ProfessionalForm from '../components/molecules/ProfessionalFrom';
import {Link} from 'react-router-dom'

// CSS
import '../constants/global.css'
import './styles/login.css'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.ALL.BACK" : "Retour au tableau de bord",
    "FORM.REGISTER-PROFESSIONAL.TITLE" : "JE CRÉE MON COMPTE PRO",
    "FORM.REGISTER-PROFESSIONAL.SUB-TITLE" : "GARANTIE À VIE FACOM",
}

function ProfessionalRegister (){
        const arrowLeft="< "
        const {contents} = useSelector((state) => state.profileReducer); 
        const {profile} = useSelector((state) => state.profileReducer); 
        const {language} = profile
        const [content,setContent] =  useState({})
        useEffect(() => {
            setContent(operateContent(contents,language,Template))
        }, [])

        return (
            <div >
                
                <div className="row Background_Gray py-5" style={{margin:'0'}}>
                    <Link to="/login">
                        <h6 className="h6-return">{arrowLeft} {content['FORM.ALL.BACK']}</h6>
                    </Link>
                    
                    <div className="row">
                        <div className="col-1 col-md-4"></div>
                        <div className="col-11 col-md-6" style={{maxWidth:'750px', textAlign:'center'}}>
                            <h2 className="h1-stl Center pt-5 "><strong>{content['FORM.REGISTER-PROFESSIONAL.TITLE']}</strong></h2>
                            <h3 className="h3-stl Center py-3 ">{content['FORM.REGISTER-PROFESSIONAL.SUB-TITLE']}</h3>
                            <div className="my-5">
                                <ProfessionalForm
                                typeOfClient = "Professional"
                                />
                            </div>
                        
                        </div>
                        <div className="col-1 col-md-2"></div>
                    </div>
                </div>
            </div>

        );
}   

export default ProfessionalRegister;