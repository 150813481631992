import React, { useState, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import '../../constants/global.css'
import './styles/button.css'

function ButtonMain(props){
    return (
        <div>
            <button type="button" style = {{width:'100%',minHeight:'63px'}}  className="btn-red py-2">
                <div className="row" style = {{width:'100%',marginLeft:'0'}}>
                    <div className="col-11" style={{paddingRight:'0'}}>
                        <strong>{props.message} <br/>  {props.message2}</strong>
                    </div>
                    <div className="col-1" style={{paddingRight:'0'}}>
                        {'>'}
                    </div>
                </div>
                
            </button>
        </div>
        );
};

export default ButtonMain;