import React, {useState} from 'react'


const useGlobalStates = () => {
    const [version, setVersion] = useState("1.6.2")
    const [language, setLanguage] = useState("US")
    const [logged, setLogged] = useState(false)
    const [user, setUser] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [siretCode, setSiretCode] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobilePhone, setMobilePhone] = useState('')
    const [createPassword, setCreatePassword] = useState('')
    const [createPasswordConfirm, setCreatePasswordConfirm] = useState('')
    const [details, setDetails] = useState('')
    const [reasonExchangeID,setReasonExchangeID] = useState('');
    const [productID,setProductID] = useState('');
    const [distributorID,setdistributorID] = useState('');
    const [inputProductSku,setInputProductSku] = useState('');
    const [isValid, setIsValid] = useState(false)
    const [isValidSamePass, setIsValidSamePass] = useState(false)
    const [isValidSiret, setIsValidSiret] = useState(false)
    const [isValidUpdate, setIsValidUpdate] = useState(false)
    const [isValidSamePassUpdate, setIsValidSamePassUpdate] = useState(false)
    const [isValidSiretUpdate, setIsValidSiretUpdate] = useState(false)
    
    return{
        zipCode, setZipCode,
        siretCode, setSiretCode,
        user, setUser,
        address, setAddress,
        city, setCity,
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        mobilePhone, setMobilePhone,
        createPassword, setCreatePassword,
        createPasswordConfirm, setCreatePasswordConfirm,
        details, setDetails,
        version, setVersion,
        reasonExchangeID,setReasonExchangeID,
        inputProductSku,setInputProductSku,
        productID,setProductID,
        distributorID,setdistributorID,
        isValid, setIsValid,
        isValidSamePass, setIsValidSamePass,
        isValidSiret, setIsValidSiret,
        isValidUpdate, setIsValidUpdate,
        isValidSamePassUpdate, setIsValidSamePassUpdate,
        isValidSiretUpdate, setIsValidSiretUpdate
    }
}
export default useGlobalStates;