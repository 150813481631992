import { axios as http } from "../frameworks_and_drivers/Axios"
import { BASE_URL } from "../config/settings.json";

const getContents = async () => { 
  
    try {
      const PATH  = "/v1/contents/get-all?language=FR"
      const { data } = await http.get(`${BASE_URL}${PATH}`)
      const { result } = data || {}
      
      return (result) ? mapContents(result.items) : []
    } catch (error) {
      console.log(error);
      return []
    }
}
/**
 * 
 * @param {Contents[]} contents 
 */
const mapContents = (contents) => { 
    return contents.map(content => {
      return {
        placeholder: content.placeholder,
        variants: content.variants
      }
    })
 }

 /**
  * 
  * @param {string} username 
  * @param {string} password 
  * @returns 
  */

const usersLogin = async (username,password) => {
  const body ={
      "username": username,
      "password": password
  }
  const URL=`${BASE_URL}/v1/users/login`;
  const response = await http.post(URL,body);
  return response;
}

const generateNewPassword = async (
  email,
  ) => {
      const body = {
          "email": email
      }
  const URL=`${BASE_URL}/v1/users/generate-password-from-end-user`;
  const response = await http.put(URL,body);
  return response;
  }

export const getAllUsersById = async (objectId) => {
    const URL=`${BASE_URL}/v1/users/${objectId}/get-one`;
    const response = await http.get(URL);
    return response;
}


export const updateUsers = async (
  objectId,
  first_name,
  last_name,
  email,
  work_phone,
  mobile_phone,
  home_phone,
  address,
  address_complement,
  city,
  country,
  zipcode,
  title,
  lenguage_active ,
  preferred_language,
  status
  ) => {
      const body = {
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "work_phone": work_phone,
        "home_phone": home_phone,
        "mobile_phone": mobile_phone,
        "birth_date": "",
        "users_settings": {
          "lenguage_active": lenguage_active,
          "preferred_language": preferred_language
        },
        "address": {
            "address": address,
            "address_complement": address_complement,
            "city": city,
            "country": country,
            "zipcode": zipcode,
        },
        "title": title,
        "status": status
    }
  const URL=`${BASE_URL}/v1/users/${objectId}/update-one`;
  const response = await http.put(URL,body);
  return response;
}

export const setUserPassword = async (
  objectId,
  password
  ) => {
      const body = {
        "password": password
    }
  const URL=`${BASE_URL}/v1/users/${objectId}/set-password`;
  const response = await http.put(URL,body);
  return response;
}

export const getAllProductsByReference = async (reference) => {
    const URL=`${BASE_URL}/v1/products/get-all?reference=${reference}&available=${true}`;
    const response = await http.get(URL);
    return response;
}

export const getAllProducts = async () => {
  const URL=`${BASE_URL}/v1/products/get-all?&available=${true}`;
  const response = await http.get(URL);
  return response;
}
export const getAllProductsByReferenceDD2 = async () => {
  const URL=`${BASE_URL}/v1/products/get-all?reference=_UNKNOWN_&available=${true}`;
  const response = await http.get(URL);
  return response;
}

export const getAllReasonExchanges = async () => {
  const URL=`${BASE_URL}/v1/reasons-for-exchange/get-all`;
  const response = await http.get(URL);
  return response;
}

export const getAllExchangesByStatus = async (status) => {
    const URL=`${BASE_URL}/v1/exchanges/get-all?status=${status}`;
    const response = await http.get(URL);
    return response;
}

export const getAllExchangesByStatusAndID = async (status,iD) => {
  const URL=`${BASE_URL}/v1/exchanges/get-all?status=${status}&customer_id=${iD}`;
  const response = await http.get(URL);
  return response;
}

export const getAllExchangesById = async (objectId) => {
  const URL=`${BASE_URL}/v1/exchanges/${objectId}/get-one`;
  const response = await http.get(URL);
  return response;
}


export const createExchange= async (
  date,
  customer_id,
  product_id,
  exchange_reason_id,
  comments,
  picture,
) => {
  const body = {
      "date": date,
      "customer_id": customer_id,
      "product_id": product_id,
      "exchange_reason_id":exchange_reason_id,
      "comments": comments,
      "quantity": 0,
      "total_cost": "0.00",
      "currency": '€',
      "geographical_coordinates": "",
      "code_direct_evaluation": "",

  }
  const URL=`${BASE_URL}/v1/exchanges/create-one`;
  const response = await http.post(URL,body);
  return response;
}

export const updateExchangesStatus = async (
  objectId,
  status
  ) => {
      const body = {
          status: status
      }
  const URL=`${BASE_URL}/v1/exchanges/${objectId}/change-status`;
  const response = await http.put(URL,body);
  return response;
}


export const updateExchanges = async (
  objectId,
  customer_id,
  distributor_id,
  product_id,
  exchange_reason_id,
  ) => {
      const body = {
          "customer_id": customer_id,
          "distributor_id":distributor_id,
          "product_id": product_id,
          "exchange_reason_id": exchange_reason_id,
      }
  const URL=`${BASE_URL}/v1/exchanges/${objectId}/update-one`;
  const response = await http.put(URL,body);
  return response;
}



export const createNewCustomerProfessional = async (
  account_type,
  first_name,
  last_name,
  email,
  mobile_phone,
  postal_code,
  siret_code,
  business,
  password,
  terms_accepted,
  privacy_policy_accepted,
  ) => {
      const body = {
        "account_type": account_type,
        "business": business,
        "siret_code": siret_code,
        "terms_accepted": terms_accepted,
        "privacy_policy_accepted": privacy_policy_accepted,
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "mobile_phone": mobile_phone,
        "postal_code": postal_code,
        "password": password,
    }
  const URL=`${BASE_URL}/v1/customers/create-one-from-end-user`;
  const response = await http.post(URL,body);
  return response;
}


export const createNewCustomerPersonal = async (
  account_type,
  first_name,
  last_name,
  email,
  mobile_phone,
  address,
  postal_code,
  city,
  password,
  terms_accepted,
  privacy_policy_accepted,
  ) => {
      const body = {
        "account_type": account_type,
        "terms_accepted": terms_accepted,
        "privacy_policy_accepted": privacy_policy_accepted,
        "first_name": first_name,
        "last_name": last_name,
        "email": email,
        "mobile_phone": mobile_phone,
        "address": address,
        "postal_code": postal_code,
        "city": city,
        "password": password,
    }
  const URL=`${BASE_URL}/v1/customers/create-one-from-end-user`;
  const response = await http.post(URL,body);
  return response;
}

export const getAllDistributors= async () => {
  const URL=`${BASE_URL}/v1/distributors/get-all?is_visible=${true}`;
  const response = await http.get(URL);
  return response;
}

export const getAllDistributorName= async (filter) => {
    const URL=`${BASE_URL}/v1/distributors/get-all?name=${filter}&is_visible=${true}`;
    const response = await http.get(URL);
    return response;
}
export const getAllDistributorPostalCode= async (filter) => {
  const URL=`${BASE_URL}/v1/distributors/get-all?postal_code=${filter}&is_visible=${true}`;
  const response = await http.get(URL);
  return response;
}

export const getDistributorsById = async (objectId) => {
  const URL=`${BASE_URL}/v1/distributors/${objectId}/get-one`;
  const response = await http.get(URL);
  return response;
}

const service = {
  getContents,usersLogin,generateNewPassword
};
export default service;
export {
  getContents,
  usersLogin,
  generateNewPassword
};



