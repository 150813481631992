import { SET_CONTENTS } from "../actions";
import { INITIAL_STATE } from ".";
import { AnyAction } from "redux";

/**
 *
 * @param {INITIAL_STATE} state
 * @param {AnyAction} action
 * @returns
 */
const contentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTENTS:
      return { ...state, contents: [...action.payload] };
    default:
      return state;
  }
};

export { contentsReducer };
