import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import '../molecules/style/loginForm.css'

import InputForm from './InputForm';

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.REGISTER-PERSONAL.ADDRESS":	"Adresse",
    "FORM.REGISTER-PERSONAL.POSTAL-CODE":	"Code Postal",
    "FORM.REGISTER-PERSONAL.CITY":	"Ville",
}
function FormParticularUser(props){
    const { zipCode, setZipCode, address, setAddress, city, setCity} = useContext(AppConext)

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    return (
        <div className="Background_White">
            <div className="row">
                {/* Falta sacar lo que se escribe*/}
                <div className = "col-12 col-md-4 pt-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PERSONAL.ADDRESS']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-4">
                    <InputForm
                        type="text"
                        name="Adresse"
                        placeholder= {content['FORM.REGISTER-PERSONAL.ADDRESS']} 
                        style={{ heigth: '250px'}}
                        currentValue={address}
                        handleOnInputValue={(data) => { setAddress(data)}}/>
                </div>
                <div className = "col-12 col-md-4 pt-2">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PERSONAL.POSTAL-CODE']} * </strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-2">
                    <InputForm
                        type="text"
                        name="Code Postal"
                        placeholder= {content['FORM.REGISTER-PERSONAL.POSTAL-CODE']}
                        style={{ heigth: '250px'}}
                        currentValue={zipCode}
                        handleOnInputValue={(data) => { setZipCode(data)}}/>
                </div>
                <div className = "col-12 col-md-4 pt-2">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PERSONAL.CITY']} * </strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-2">
                    <InputForm
                        type="text"
                        name="Ville"
                        placeholder= {content['FORM.REGISTER-PERSONAL.CITY']}
                        style={{ heigth: '250px'}}
                        currentValue={city}
                        handleOnInputValue={(data) => { setCity(data)}}/>
                </div>
            </div>
        </div>

        );
};

export default FormParticularUser;