import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { Nav} from 'react-bootstrap';
import './styles/NavMenu.css'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "MENU.LOGIN-HOME.BRAND-ITEM" : "LA MARQUE",
    "MENU.LOGIN-HOME.PRODUCTS-ITEM" : "PRODUITS",
    "MENU.LOGIN-HOME.SOLUTIONS-ITEM" : "SOLUTIONS",
    "HOME.LOGIN-MENU.SERVICES-ITEM" : "SERVICES",
    "MENU.LOGIN-HOME.CONTACT-ITEM" : "CONTACT",
    "MENU.LOGIN-HOME.COMMUNITY-ITEM" : "LA FACOMMUNAUTÉ"
}


export default function NavMenu(props) {
    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    return(
        <div >
            <Nav className="justify-content-start" activeKey="/home">
                <Nav.Item>
                    <Nav.Link href="https://www.facom.fr/" className="text-nav-stl ">{content['MENU.LOGIN-HOME.BRAND-ITEM']}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.facom.fr/" className="text-nav-stl ">{content['MENU.LOGIN-HOME.PRODUCTS-ITEM']}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.facom.fr/" eventKey="link-2" className="text-nav-stl ">{content['MENU.LOGIN-HOME.SOLUTIONS-ITEM']}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.facom.fr/" target="_blanck" className="text-nav-stl ">{content['HOME.LOGIN-MENU.SERVICES-ITEM']}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.facom.fr/" target="_blanck" className="text-nav-stl ">{content['MENU.LOGIN-HOME.CONTACT-ITEM']}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.facom.fr/" target="_blanck" className="text-nav-stl ">{content['MENU.LOGIN-HOME.COMMUNITY-ITEM']}</Nav.Link>
                </Nav.Item>
            </Nav>
        
        </div>
    );
}