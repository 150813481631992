import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import '../molecules/style/loginForm.css'
import ValidationAlert from './ValidationAlert';

import InputForm from './InputForm';

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.REGISTER-PROFESSIONAL.PASSWORD": "Mot de passe",
    "FORM.REGISTER-PROFESSIONAL.CONFIRMATION":"Confirmation",
}

function FormLastUser(props){

    const {createPassword, setCreatePassword,createPasswordConfirm, setCreatePasswordConfirm,isValid, setIsValid,isValidSamePass, setIsValidSamePass} = useContext(AppConext)

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    if(createPassword === createPasswordConfirm){
        setIsValidSamePass(true)
    }else{
        setIsValidSamePass(false)
    }
    
    // function isValidPostalCode() {
    //     const postalCodeRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$-_%^&*]{6,16}$/;;
    //     setIsValid(postalCodeRegex.test(createPassword))
    // }
    // console.log({isValid},{createPassword})
    return (
        <div className="Background_White">
            <div className="row">
                <div className = "col-12 col-md-4 mt-3 pt-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.PASSWORD']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6 mt-3 pt-4">
                    <InputForm
                        type="password"
                        name="password"
                        placeholder= {content['FORM.REGISTER-PROFESSIONAL.PASSWORD']}
                        style={{ heigth: '250px'}}
                        currentValue={createPassword}
                        handleOnInputValue={(data) => {
                            setCreatePassword(data)
                                const postalCodeRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$-_%^&*]{6,16}$/;
                                setIsValid(postalCodeRegex.test(data))
                            }}/>
                </div>
                <div className = "col-12 col-md-4 pt-2">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.CONFIRMATION']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-2">
                    <InputForm
                        type="password"
                        name="passwordConf"
                        placeholder= {content['FORM.REGISTER-PROFESSIONAL.CONFIRMATION']}
                        style={{ heigth: '250px'}}
                        currentValue={createPasswordConfirm}
                        handleOnInputValue={(data) => { setCreatePasswordConfirm(data)
                        }}/>

                        {/* // con && aparece el 0 */}
                        {createPassword.length ?
                            <div>
                                {!isValid && 
                                <div className='py-4'>
                                    <ValidationAlert message="The password must contain: / 6 - 16 characters and a capital letter / A number / A special character"/>
                                </div>}
                            </div>
                        :""
                        } 
                        <div className='py-2'>
                            
                        </div>
                        {createPassword.length ?
                            <div>
                                {!isValidSamePass &&
                                <div className='py-4'>
                                    <ValidationAlert message="Passwords doesn't match"/> 
                                </div>}
                            </div>
                        :""
                    }
                </div>
            </div>
        </div>
        );
};

export default FormLastUser;