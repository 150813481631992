import React, { useRef, useState, useEffect, useContext} from 'react';
import debounce from 'lodash.debounce';
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from '@zendeskgarden/react-dropdowns';
import { Row, Col } from '@zendeskgarden/react-grid';
import check from '../../images/check.png'
import unCheck from '../../images/uncheck.png'
import '../../constants/global.css'
import AppConext from '../../context/AppContext';

const DropDownReasonEx= (props) => {
    const {setReasonExchangeID}= useContext(AppConext);
    // const [selectedItem, setSelectedItem] = useState(props.options[0]);

    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState(props.options);

    const [elegibleExchange,setElegibleExchange]= useState(true);

   

    /**
     * Debounce filtering
     */
    const filterMatchingOptionsRef = useRef(
        debounce((value) => {
        const matchedOptions = props.options?.filter(
            option => option?.label.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        );

        setMatchingOptions(matchedOptions);
        }, 300)
    );

    useEffect(() => {
        filterMatchingOptionsRef.current(inputValue);
    }, [inputValue]);

    const handleClick = (optionRefused,idRefused) =>{
        setElegibleExchange(optionRefused)
        setReasonExchangeID(idRefused)
    }

    return (
        <Row justifyContent="left">
        <Col sm={12}>
            <Dropdown
            inputValue={inputValue}
            selectedItem={props.selectedItem}
            onSelect={props.onSelect}
            onInputValueChange={value => setInputValue(value)}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
            >
            <Field>
                <Autocomplete
                style = {{
                    color:  '#2BE17B',
                    borderRadius:'2px',
                    textAlign:'left',
                    fontFamily: 'Arial',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    width:'100%',
                    height:'30px',
                    border:'1px solid #ced4da'
                    }}>
                        {elegibleExchange === true ?
                        <div style={{display:'flex'}}>
                            <span style={{color:'#2BE17B'}}>{props.selectedItem}</span>
                            <img style={{position:'absolute',right:'5%',width:'20px'}} src={check}></img>
                        </div>
                            :
                            <div style={{display:'flex'}}>
                                <span style={{color:'#e60003'}}>{props.selectedItem}</span>
                                <img style={{position:'absolute',right:'5%',width:'20px'}} src={unCheck}></img>
                            </div>
                            
                        }

                    </Autocomplete>
            </Field>
            <Menu style = {{backgroundColor: '#FFF',
                    color: '#818181',
                    borderRadius:'2px',
                    fontFamily: 'Arial',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',

                    }}>
                {matchingOptions.length ? (
                matchingOptions.map(option => (
                    
                    <Item key={option.label} value={option.label} onClick={()=>handleClick(option.refused,option.id)}>
                        {option.refused === true ?
                        <div style={{display:'flex'}}>
                            <span style={{color:'#2BE17B'}}>{option?.label}</span>
                            <img style={{position:'absolute',right:'5%',width:'20px'}} src={check}></img>
                        </div>
                            :
                            <div style={{display:'flex'}}>
                                <span style={{color:'#e60003'}}>{option?.label}</span>
                                <img style={{position:'absolute',right:'5%',width:'20px'}} src={unCheck}></img>
                            </div>
                            
                        }
                    </Item>
                ))
                ) : (
                <Item disabled>No matches found</Item>
                )}
            </Menu>
            </Dropdown>
        </Col>
        </Row>
    );
};

export default DropDownReasonEx