import './App.css';
import React ,{useEffect,useState,useContext} from 'react'

import {BrowserRouter,Switch,Route} from 'react-router-dom';

import LoginPage from './pages/Login'
import ProfessionalRegister from './pages/ProfessionalRegister';
import ParticularRegister from './pages/ParticularRegister';
import PersonalInfoForm from './pages/PersonalInfoForm';
import WarrantyPage from './pages/WarrantyPage';
import MainPage from './pages/MainPage';
import TrackingState from './pages/TrakingState';
import {Redirect} from 'react-router-dom'
import { getContents } from './api/facom_services';
import { useSelector,useDispatch } from 'react-redux';
import { setContents } from './redux/actions/contents';

import DetailsExchange from './pages/DetailsExchange';
import HeaderMain from './components/molecules/HeaderMain';
import FooterLogin from './components/molecules/FooterLogin';
import {Link, useHistory} from 'react-router-dom'
import Swal from 'sweetalert2';
import { Nav} from 'react-bootstrap';
// CSS
import './constants/global.css'
import './pages/styles/login.css'

import facomLogo from './images/Facom_logo.png'
import houseIcon from './images/house.svg'
import AppConext from './context/AppContext';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import MenuEnglish from './images/MenuEnglish.png'
import MenuFrench from './images/MenuFrance.png'
import arrowRed from './images/ArrowbuttonRed.png'

import SelectDistributor from './pages/SelectDistributor'

import { clearState, setProfile } from './redux/actions/profile_actions'


function useWindowSize(){
    const[windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        function handleResize(){
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize',handleResize);
        handleResize()
        return () => window.removeEventListener('resize',handleResize)
    },[])
    return windowSize
    }

const Routes = (props) => {  

    const {width,height} = useWindowSize();
    const {version, distributorID}= useContext(AppConext);
    //Redux
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.profileReducer);  
    const { token } = profile
    console.log('token',token)

    const history = useHistory()

    const successContent= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'language updated',
            confirmButtonText: 'Reload',
        }).then((result) => {
            window.location.reload()
            })
        }
    //Redux

    const { language } = profile
    const handleLanguageEnglish = () =>{
        try{
            dispatch(setContents([]))
            dispatch(setProfile({ language : "EN"}))
        }catch{

        }finally{
            successContent()
        }
    
    }
    const handleLanguageFrench= () =>{
        try{
            dispatch(setContents([]))
            dispatch(setProfile({ language : "FR"}))
        }catch{

        }finally{
            successContent()
    }
    }

    //menu fade
        const [anchorEl, setAnchorEl] = useState(null);
        const opend = (anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };


    const renderMenuNav = ()=>{
        return(
            <div style={{backgroundColor:'#000', margin:'0'}}>
                <HeaderMain/>
            </div>
        );
    }

    const renderMenuNavPhone = ()=>{
        return(
            <div style={{backgroundColor:'#000', display:'flex', position:'relative', height: '43px'}}>
                <div>
                    <img src={facomLogo} style={{paddingTop:'0.5em',width:'70%'}}></img>
                </div>
                <div style={{display:"flex"}}>
                    <div>
                        <Nav style={{position:'absolute', right:'10%'}}  activeKey="/home">
                        <Nav.Item>
                            <Nav.Link onClick={handleClick} className="text-nav-stl_mob">{language} <img style={{width:'25%'}} src={arrowRed}></img> </Nav.Link>
                        </Nav.Item>
                            <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={opend}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                >                          
                                    <MenuItem onClick={() => handleLanguageEnglish()}  style={{ fontSize: '12px',borderBottom: 'solid 1px #e0e0e0',background: '#eeeeef',width:'65px'}}>
                                        <img alt="" className="languaje_brand"src = {MenuEnglish} style={language === "EN" ? {width:'30px',height:'30px',borderBottom: 'solid 1px #333'} :  {width:'25px',height:'25px'}}></img>
                                    </MenuItem>
                                    <MenuItem  onClick={() => handleLanguageFrench()} style={{ fontSize: '12px',borderBottom: 'solid 1px #e0e0e0',background: '#eeeeef',width:'65px'}}>
                                                <img alt="" className='languaje_brand' src = {MenuFrench} style={ language === "EN" ?{width:'30px',height:'30px'}:{width:'25px',height:'25px',borderBottom: 'solid 1px #333'}}></img>
                                    </MenuItem>
                                </Menu>
                        </Nav>
                    </div>
                    <Link to ="/">
                    <div>
                        <img style={{position:'absolute', right:'0',width:'10%'}} src={houseIcon}  ></img>
                    </div>
                    </Link>

                </div>
                
                
            </div>
        );
    }
    return (
        <BrowserRouter>
        <div>
            <div>
            {width > 450 ? renderMenuNav() : renderMenuNavPhone()}
            </div>
                {token != '' ?
                <Switch>
                    <Route exact path="/login" component={LoginPage}><Redirect to="/"> </Redirect></Route>
                    <Route exact path="/" component={MainPage} />
                    <Route exact path="/register/professional" component={ProfessionalRegister} />
                    <Route exact path="/register/individual" component={ParticularRegister} />
                    <Route exact path="/account/update" component={PersonalInfoForm} />
                    <Route exact path="/demand" component={WarrantyPage} />
                    <Route exact path="/historic" component={TrackingState} />
                    <Route exact path="/details/:id" render={(routerProps) => <DetailsExchange id={routerProps.match.params.id} />} />
                    <Route exact path="/demand/:id" render={(routerProps) => <SelectDistributor id={routerProps.match.params.id} />} />
                </Switch>   
                :
                <Switch>
                    <Route exact path="/" component={PersonalInfoForm}><Redirect to="/login"> </Redirect></Route>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/register/professional" component={ProfessionalRegister}/>
                    <Route exact path="/register/individual" component={ParticularRegister} />
                    <Route exact path="/account/update" component={PersonalInfoForm}><Redirect to="/login"> </Redirect></Route>
                    <Route exact path="/demand" component={WarrantyPage} ><Redirect to="/login"> </Redirect></Route>
                    <Route exact path="/historic" component={TrackingState}><Redirect to="/login"> </Redirect></Route>
                    <Route exact path="/details/:id" render={(routerProps) => <DetailsExchange id={routerProps.match.params.id} />} ><Redirect to="/login"> </Redirect></Route>
                </Switch>  
                }
                {width > 500 &&
                <div>
                    <FooterLogin/>
                </div>
                }
                <div className="py-2 " style={{backgroundColor:'#000'}}>
                        <div className="row">
                                <h5 style={{fontSize:'10px'}} className="h5-stl-footer Center">Version {version}</h5>
                        </div>
                    </div>
            </div>
        </BrowserRouter>
    );
}

export default Routes;
