import './App.css';
import React ,{useState,useEffect} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import { fetchContent } from './frameworks_and_drivers/Axios'
import Routes from './Routes'
import LoadingSkeleton from './components/atoms/LoadingSkeleton'
import Swal from 'sweetalert2'

const successContent= ()=>{
  Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'New content!!',
      confirmButtonText: 'Reload',
  }).then((result) => {
          window.location.reload()
    })
}
const alertWrongGet = ()=>{
  Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something has gone wrong with contents!',
  }) 
}


const MainComponent = (props) => { 
  const {contents} = useSelector((state) => state.profileReducer); 
  console.log(contents)

  useEffect(() => {
    try{
      fetchContent()
      if(!contents.length){
        successContent()
        // window.location.reload()
      }
    }catch(error){
      alertWrongGet()
    }
  }, [])
 
  const [loading,setLoading] = useState(false)
  // useEffect(() => {
  //     
  // }, [])

  return (
    <div>
      <Routes
      />
    </div>
  );
}

export default MainComponent;
