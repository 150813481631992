import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import '../../constants/global.css'
import Table from 'react-bootstrap/Table'
import ButtonClean from './ButtonClean';
import ButtonLoggout from './ButtonLoggout';
import { updateExchangesStatus } from '../../api/facom_services'
import { Link } from "react-router-dom"
import Swal from 'sweetalert2'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "TABLE-TRACKING-WARRANTY.TITLE-TYPE":	"TYPE D'OUTIL",
    "TABLE-TRACKING-WARRANTY.TITLE-BREAKAGE":	"Type de casse",
    "TABLE-TRACKING-WARRANTY.TITLE-DATE":	"Date de la demande",
    "TABLE-TRACKING-WARRANTY.TITLE-ACTIONS":	"Actions",
    "TABLE-TRACKING-WARRANTY.BUTTON-GIVE":	"ABANDONNER",
    "TABLE-TRACKING-WARRANTY.BUTTON-DISTRIBUTOR-CHOICE":	"CHOIX DISTRIBUTEUR",
    "TABLE-TRACKING-WARRANTY.BUTTON-DETAIL":	"VOIR LE DÉTAIL",
}

function TableState(props){

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    //Call api
    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Success Abandonned',
        })
    }
    const updateStatus = async (id) =>{
        try{
            const response = updateExchangesStatus(id,"Abandonned");  
            const data = (await response).data;
            alertSuccess()
            return(data)
        }catch(error){
            console.log("error update")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            props.reload()
        }  
    }

    return (
        <div>
            {props.list?.length != 0 ?
            <Table responsive="xl">
                <thead style={{marginBottom:'10px',textAlign:'left'}}>
                <tr>
                    <th>{content['TABLE-TRACKING-WARRANTY.TITLE-TYPE']}</th>
                    <th>{content['TABLE-TRACKING-WARRANTY.TITLE-BREAKAGE']}</th>
                    <th>{content['TABLE-TRACKING-WARRANTY.TITLE-DATE']}</th>
                    <th style={{textAlign:'right', paddingRight:'30px'}}>{content['TABLE-TRACKING-WARRANTY.TITLE-ACTIONS']}</th>
                </tr>
                </thead>
                
                <tbody >
                    {props.list?.map((row)=>(
                    <tr style={{backgroundColor:'rgb(242,242,242)',borderBottom:'5px solid #FFF',textAlign:'left'}}>
                        <td style={{paddingLeft:'15px'}}>{row?.product_data?.reference}</td>
                        <td style={{paddingLeft:'15px'}}>{row?.exchange_reason_data?.label}</td>
                        <td style={{paddingLeft:'15px'}}>{row.date}</td>
                        <td >{props.State === "DRAFT" ? 
                            <div style={{display:'block'}}>
                                <div style={{display:'block'}}>
                                    <ButtonClean
                                    message={content['TABLE-TRACKING-WARRANTY.BUTTON-GIVE']}
                                    onClick = {() => updateStatus(row._id)}
                                    width= "130px"
                                    float = "right"/>
                                </div>
                                <br/>
                                <br/>
                                <Link to={`/demand/${row._id}`}>
                                    <div style={{display:'block'}}>
                                        <ButtonLoggout
                                        message = {content['TABLE-TRACKING-WARRANTY.BUTTON-DISTRIBUTOR-CHOICE']}
                                        width = "130px"
                                        />
                                    </div>
                                </Link>
                                
                            </div> :
                            <Link to={`/details/${row._id}`}>
                                <div>
                                    <ButtonLoggout
                                        message = {content['TABLE-TRACKING-WARRANTY.BUTTON-DETAIL']}
                                        width = "130px"
                                        />
                                </div>
                            </Link>}
                        </td>
                    </tr>
                    ))}
                </tbody>
            </Table>
        : ""}
        </div>
        );
};

export default TableState;
