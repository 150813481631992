import React, { useRef, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from '@zendeskgarden/react-dropdowns';
import { Row, Col } from '@zendeskgarden/react-grid';

import '../../constants/global.css'


const options = [
    'Asparagus',
    'Brussel sprouts',
    'Cauliflower',
    'Garlic',
    'Jerusalem artichoke',
    'Kale',
    'Lettuce',
    'Onion',
    'Mushroom',
    'Potato',
    'Radish',
    'Spinach',
    'Tomato',
    'Yam',
    'Zucchini'
];

const DropDownWhite = (props) => {
    // const [selectedItem, setSelectedItem] = useState(props.options[0]);
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState(props.options);

    /**
     * Debounce filtering
     */
    const filterMatchingOptionsRef = useRef(
        debounce((value) => {
        const matchedOptions = props.options.filter(
            option => option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
        );

        setMatchingOptions(matchedOptions);
        }, 300)
    );

    useEffect(() => {
        filterMatchingOptionsRef.current(inputValue);
    }, [inputValue]);

    return (
        <Row justifyContent="left" style={{justifyContent:'left',width:'100%'}}>
        <Col sm={12}>
            <Dropdown
            inputValue={inputValue}
            selectedItem={props.selectedItem}
            onSelect={props.onSelect}
            onInputValueChange={value => setInputValue(value)}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
            >
            <Field>
                <Autocomplete
                style = {{
                    color:  '#818181',
                    borderRadius:'2px',
                    textAlign:'left',
                    fontFamily: 'Arial',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    width:'100%',
                    maxWidth:'330px',
                    height:'30px',
                    border:'1px solid #ced4da'
                    }}>{props.selectedItem}</Autocomplete>
            </Field>
            <Menu style = {{backgroundColor: '#FFF',
                    color: '#818181',
                    borderRadius:'2px',
                    fontFamily: 'Arial',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',

                    }}>
                {matchingOptions.length ? (
                matchingOptions.map(option => (
                    <Item key={option} value={option}>
                    <span>{option}</span>
                    </Item>
                ))
                ) : (
                <Item disabled>No matches found</Item>
                )}
            </Menu>
            </Dropdown>
        </Col>
        </Row>
    );
};

export default DropDownWhite;