import React, { useState, useRef, useContext, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import './style/loginForm.css'
import { Link } from 'react-router-dom';
import { usersLogin, getAllUsersById } from '../../api/facom_services'
import {decodeJwtToken} from '../atoms/decode'
import Button from '../atoms/Button';
import { useDispatch } from 'react-redux'
import { setProfile,clearState } from '../../redux/actions/profile_actions'
import { setContents } from '../../redux/actions/contents';
import Swal from 'sweetalert2'
import InputForm from '../atoms/InputForm';
import LoadingPag from '../atoms/LoadingPag';

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "SING-IN-BOX.LOGIN-HOME.TITLE" : "SIGN IN",
    "SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL" : "E-mail",
    "SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD" : "Mot de passe",
    "SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL-PLACEHOLDER" : "Mon adresse e-mail",
    "SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD-PLACEHOLDER" : "mon mot de passe",
    "SING-IN-BOX.LOGIN-HOME.BUTTON" : "ME CONNECTER",
}

function LoginForm(props){
    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
  
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    

    // window.addEventListener("keydown", function(e){
    //     if(e.key == "Enter"){
    //         loginAccount()
    //     }
    // })

    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

  

    
    const alertWrongGet = (text)=>{
        Swal.fire({
            icon: 'error', 
            title: 'Oops...',
            text: `${text}`
        })
    }
    const dispatch = useDispatch();
    const loginAccount = async () =>{
        setLoading(!loading)                   
            try{
                const response = usersLogin(user,password);  
                const data = (await response).data;
                const userDec =  decodeJwtToken(data.result?.token,{complete:true})
                
                if(userDec){
                    dispatch(setProfile({ token : data.result?.token}))
                    dispatch(setProfile({
                        email: userDec.payload.email,
                        full_name: userDec.payload.full_name,
                        sub: userDec.payload.sub,
                        username: userDec.payload.username,
                    }))
                    //Languaje
                    const response = getAllUsersById(userDec.payload.sub);  
                    const datauser = (await response).data;
                    const activeLanguaje = `${datauser?.result?.users_settings?.lenguage_active}`.toLowerCase()
                    let languageACT = 'FR' 
                    if(activeLanguaje === 'english'){
                        languageACT = 'EN'
                    }
                    if(activeLanguaje === 'french'){
                        languageACT = 'FR'
                    }
                    dispatch(setContents([]))
                    dispatch(setProfile({ language : languageACT}))
                }
                return(data)
            }catch(error){
                    console.log("error get customer")
                    console.log(error)
                    alertWrongGet(error.response?.data?.message)
                    dispatch(clearState())
                    
            }finally{
                setLoading(false)
                console.log("end")
                window.location.reload()
            }  
        }

        const handleSubmit = (event) =>{
            event.preventDefault()
            loginAccount()
        }
    return (
        <div className="py-5 px-4 Background_White">
            <h2 className="h2-stl Center "><strong>{content['SING-IN-BOX.LOGIN-HOME.TITLE']}</strong></h2>
            <div className="line-loggin"></div>
            <div className="row">
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL']}</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <form action = "/" onSubmit={handleSubmit}>
                        <InputForm
                            type="text"
                            name="User"
                            placeholder= {`${content['SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL-PLACEHOLDER']}`}
                            style={{ heigth: '250px'}}
                            currentValue={user}
                            handleOnInputValue={(data) => { setUser(data)}}/>
                        </form>
                </div>
                <div className='py-2'>
                    

                </div>
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l pt-3"><strong>{content['SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD']}</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <form action = "/" onSubmit={handleSubmit}>
                        <InputForm
                            type="password"
                            name="User"
                            placeholder = {`${content['SING-IN-BOX.LOGIN-HOME.INPUT-PASSWORD-PLACEHOLDER']}`}
                            style={{ heigth: '250px'}}
                            currentValue={password}
                            handleOnInputValue={(data) => { setPassword(data)}}/>
                    </form>
                    <div className="py-1">
                        
                    </div>
                    <form action = "/" onSubmit={handleSubmit}>
                        <input  type="submit" value="Connexion" style={{display:'none'}}></input>
                    </form>
                        <Button
                        message= {`${content['SING-IN-BOX.LOGIN-HOME.BUTTON']}`}
                        onClick = {()=>loginAccount()}/>
                    
                </div>
                <div className = "col-0 col-md-2">
                    
                </div>
            </div>
            <LoadingPag
                open = {loading}/>
            
        </div>

        );
};

export default LoginForm;