import axios from 'axios';

import { STORE } from '../redux';
import { Profile } from '../redux/actions/profile_actions'

import { getContents } from '../api/facom_services';
import { setContents } from '../redux/actions/contents';
const unlessPaths = ['/login','/'];

const fetchContent = async() => { 
  const result = await getContents()
  STORE.dispatch(setContents(result))
}

const setInterceptors = () => {
    let data = {};
  
    const listener = () => {
      data = STORE.getState().profileReducer.profile;
    };
  
    STORE.subscribe(listener);
  
    const unless = (url, paths) => {
      return !paths.findIndex(path => url.includes(path));
    };
  
    const onRequest = (request) => {
      const URL = request.url;
      if (URL && !unless(URL, unlessPaths))
        if (request.headers && data.token)
          request.headers['Authorization'] = `Bearer ${data.token}`;
          // request.headers['Authorization'] = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjYxOTUzOWIyNGUwYTBlMzlhZWYyMDBkNSJ9.eyJ1c2VybmFtZSI6InNlYmFzdGlhbiIsImVtYWlsIjoic2NhcmRlbmFzQGdtYWlsLmNvbSIsImZ1bGxfbmFtZSI6InNlYmFzdGlhbiBjYXJkZW5hcyIsInJvbGVzIjpbIjYxOTU2NDJlZGZhOGFjYjVmOTc1N2ExNiJdLCJpYXQiOjE2MzcxODMyMzksImV4cCI6MTY1NDQ2MzIzOSwiaXNzIjoiZmFjb20iLCJzdWIiOiI2MThkODEwNGU5NmQ1MjAwMTFlMmM3NzIiLCJqdGkiOiIyNDhjYjBmYi1jZmI2LTQ4OWEtOGE4OS1jODEyNzQ3NjZhOTEifQ.dTBXSuAuZ6mLHwBjZkJLoXa-AmAnzLInU2tMPbxKYQlg4UkpobrhtvyEt3jQPmxbnfO83Q41ZWJE8IjQ925GQj2EtIoElnVJZ8_3U45XEMN1FTmxFvgxdHnHP3V52yF3i8yTntPiDqk3Of8PsP1B_hO--yCXigwecDM0IPxVBNktz-jn6UvNC7IRCUBETm3BMeb9NfKBgvO48NAUoXGpq9_5ZUXEE2vpCHRc9U-hu9CS5crzZTSdZZxmpIebxOJStACDUJcYYZphe-x2eRef3B465Rd1hS1Ss4-n9wphY7dUXUd8Ph48_PAKhMlw2PNZSXVW97UFxY10AHvJh07nAA`;
      return request;
    };
  
    const onError = (err) => {
      return Promise.reject(err);
    };
  
    axios.interceptors.request.use(onRequest, onError);
  };

const service = {
    axios,
    fetchContent
};
export default service;
export {
    axios,setInterceptors,fetchContent
};
