import React, {useContext, useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../context/AppContext';

// COMPONENTS
import Button from '../components/atoms/Button';
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import { getAllUsersById, updateUsers, setUserPassword} from '../api/facom_services'
import LoadingFacom from '../components/atoms/LoadingFacom';
import Swal from 'sweetalert2'
import InputForm from '../components/atoms/InputForm';
import DropDownWhite from '../components/atoms/DropDownWhite';
import DropDownCities from '../components/atoms/DropDownCities';
// CSS
import '../constants/global.css'
import './styles/login.css'
import ValidationAlert from '../components/atoms/ValidationAlert';
//contents
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM-PERSONAL-INFO.TITLE":	"IDENTITÉ",
    "FORM.PERSONAL.INFO.FIRST-NAME":	"Prénom",
    "FORM.PERSONAL.INFO.LAST-NAME":	"Nom",
    "FORM.PERSONAL.INFO.EMAIL":	"Email",
    "FORM.PERSONAL.INFO.MOBILE-PHONE":	"Tel Mobile",
    "FORM.PERSONAL.INFO.TEL-FIX":	"Tel fix",
    "FORM.PERSONAL.INFO.TEL-JOB":	"Tel Job",
    "FORM.PERSONAL.INFO.FAX":	"fax",
    "FORM.PERSONAL.INFO.ADDRESS":	"Adresse 1",
    "FORM.PERSONAL.INFO.ADDRESS-COMPLEMENT":	"Adresse 2",
    "FORM.PERSONAL.INFO-POSTAL-CODE":	"Code Postal",
    "FORM.PERSONAL.INFO-CITY":	"Ville",
    "FORM.PERSONAL.INFO-COUNTRY":	"Pays",
    "FORM.PERSONAL.INFO-PASSWORD":	"Mot de passe",
    "FORM.PERSONAL.INFO-CONFIRMATION":	"Confirmation",
    "FORM.PERSONAL.INFO-BUTTON":	"METTRE À JOUR MES INFORMATIONS",
    "FORM.ALL.BACK": "Retour au tableau de bord"
    }
const listaPaysOptions = [
    'France',
    'Spain',
    'United Kingdom',
    'Germany'
]


function PersonalInfoForm (props){
    const { profile } = useSelector((state) => state.profileReducer);  
    const { sub } = profile
    const { isValidUpdate, setIsValidUpdate, isValidSamePassUpdate, setIsValidSamePassUpdate,setCreatePassword,setCreatePasswordConfirm}= useContext(AppConext);

    const {contents} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
        setCreatePassword('')
        setCreatePasswordConfirm('')
    }, [])

    
    const [selectedCountry, setSelectedCountry] = useState(listaPaysOptions[0])
    const [firstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobilePhone, setMobilePhone] = useState('')
    const [workPhone, setWorkPhone] = useState('-')
    const [homePhone, setHomePhone] = useState('-')
    const [faxPhone, setFaxPhone] = useState('-')
    const [address, setAddress] = useState('')
    const [addressComplement, setAddressComplement] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [title, setTitle]  = useState('')
    const [preferedLanguage, setPreferedlanguage]  = useState('')
    const [languageActive, setLanguageActive]  = useState('')
    const [status, setStatus]  = useState('')

    if(password === passwordConfirm){
        setIsValidSamePassUpdate(true)
    }else{
        setIsValidSamePassUpdate(false)
    }
  
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        try{
            getUser()
        }catch(error){

        }finally{
        }
    }, [])
        //Call api
        const alertWrongGet = ()=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something has gone wrong please try again later!',
            })
        }
        //Call api
        const alertWrongPassword = ()=>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid Password',
            })
        }

       //!-------------------USER------------------------
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'User has been updated',
        })
    }
    const getUser = async () =>{
        setLoading(!loading)
        try{
            const response = getAllUsersById(sub);  
            const data = (await response).data;
            setFirstName(data.result?.first_name)
            setLastName(data.result?.last_name)
            setMobilePhone(data.result?.work_phone)
            setWorkPhone(data.result?.mobile_phone)
            setHomePhone(data.result?.home_phone)
            setEmail(data.result?.email)
            // setFaxPhone(data.result?.work_phone)
            setAddress(data.result?.address?.address)
            setAddressComplement(data.result?.address?.address_complement)
            setPostalCode(data.result?.address?.zipcode)
            setCity(data.result?.address?.city)
            setCountry(data.result?.address?.country)
            setTitle(data.result?.title)
            setPreferedlanguage(data.result?.users_settings?.preferred_language)
            setLanguageActive(data.result?.users_settings?.lenguage_active)
            setStatus(data.result?.status)
            setPassword('')
            setPasswordConfirm('')
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }

    const updateInfo = async (UserID) =>{
        setLoading(!loading)
        
            try{
                if(password.length){
                    if(isValidUpdate && isValidSamePassUpdate){
                        const response = updateUsers(
                            sub,
                            firstName,
                            LastName,
                            email,
                            workPhone,
                            mobilePhone,
                            homePhone,
                            address,
                            addressComplement,
                            city,
                            country,
                            postalCode,
                            title,
                            languageActive,
                            preferedLanguage,
                            status
                        );  
                        const data = (await response).data;
                        if(password === passwordConfirm){
                            setUserPassword(
                                sub,
                                password
                            )
                        }
                        alertSuccess()
                    }else{
                        alertWrongPassword()
                    }
                    
                }else{
                    const response = updateUsers(
                        sub,
                        firstName,
                        LastName,
                        email,
                        workPhone,
                        mobilePhone,
                        homePhone,
                        address,
                        addressComplement,
                        city,
                        country,
                        postalCode,
                        title,
                        languageActive,
                        preferedLanguage,
                        status
                    );  
                    const data = (await response).data;
                    alertSuccess()
                }
            
            }catch(error){
                console.log("error update")
                console.log(error)
                alertWrongGet()
            }finally{
                console.log("end")
                setLoading(false)
            }  
        
    }
        const arrowLeft="< "
        return (
            <div >
                    <div className="row Background_Gray py-5"  style={{margin:'0'}}>
                        {!loading ?
                            <div>
                                <Link to="/">
                                    <h6 className="h6-return">{arrowLeft} {content['FORM.ALL.BACK']}</h6>
                                </Link>
                                
                                <div className="row px-3">
                                    <div className="col-0 col-md-4"></div>
                                    <div className="col-12 col-md-6" style={{maxWidth:'750px', textAlign:'center'}}>
                                        <div className="pt-5"></div>
                                        <h3 className="h3-stl Center pt-3 ">{content['FORM-PERSONAL-INFO.TITLE']}</h3>
                                        <div className="my-5">
                                        <div className="py-5 px-4 Background_White">
                                            <div className="row">
                                            <div>
                                                {/* parte 1 */}
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.FIRST-NAME']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="text"
                                                            name="firstName"
                                                            placeholder={content['FORM.PERSONAL.INFO.FIRST-NAME']}
                                                            style={{ heigth: '250px'}}
                                                            currentValue={firstName}
                                                            handleOnInputValue={(data) => { setFirstName(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.LAST-NAME']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="text"
                                                            name="userLastName"
                                                            placeholder= {content['FORM.PERSONAL.INFO.LAST-NAME']}
                                                            style={{ heigth: '250px'}}
                                                            currentValue={LastName}
                                                            handleOnInputValue={(data) => { setLastName(data)}}/>
                                                    </div>
                                                </div>

                                                {/* parte 2 */}
                                                <div className="py-3"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.EMAIL']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="email"
                                                            name="userEmail"
                                                            readOnly
                                                            placeholder= {content['FORM.PERSONAL.INFO.EMAIL']} 
                                                            style={{ heigth: '250px', background: 'rgb(233, 236, 239)'}}
                                                            currentValue={email}
                                                            handleOnInputValue={(data) => { setEmail(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.MOBILE-PHONE']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="number"
                                                            name= {content['FORM.PERSONAL.INFO.MOBILE-PHONE']}
                                                            placeholder="Teléphone mobile"
                                                            style={{ heigth: '250px'}}
                                                            currentValue={mobilePhone}
                                                            handleOnInputValue={(data) => { setMobilePhone(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.TEL-FIX']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="number"
                                                            name="userPhoneFix"
                                                            placeholder= {content['FORM.PERSONAL.INFO.TEL-FIX']} 
                                                            style={{ heigth: '250px'}}
                                                            currentValue={homePhone}
                                                            handleOnInputValue={(data) => { setHomePhone(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.TEL-JOB']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="number"
                                                            name="userPhoneJob"
                                                            placeholder= {content['FORM.PERSONAL.INFO.TEL-JOB']} 
                                                            style={{ heigth: '250px'}}
                                                            currentValue={workPhone}
                                                            handleOnInputValue={(data) => { setWorkPhone(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.FAX']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="number"
                                                            name="userFax"
                                                            placeholder= {content['FORM.PERSONAL.INFO.FAX']} 
                                                            style={{ heigth: '250px'}}
                                                            currentValue={faxPhone}
                                                            handleOnInputValue={(data) => { setFaxPhone(data)}}/>
                                                    </div>
                                                </div>

                                                {/* parte 3 */}
                                                <div className="py-3"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.ADDRESS']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="text"
                                                            name="userAdress"
                                                            placeholder = {content['FORM.PERSONAL.INFO.ADDRESS']}
                                                            style={{ heigth: '250px'}}
                                                            currentValue={address}
                                                            handleOnInputValue={(data) => { setAddress(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO.ADDRESS-COMPLEMENT']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="text"
                                                            name="userAdress2"
                                                            placeholder= {content['FORM.PERSONAL.INFO.ADDRESS-COMPLEMENT']}
                                                            style={{ heigth: '250px'}}
                                                            currentValue={addressComplement}
                                                            handleOnInputValue={(data) => { setAddressComplement(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO-POSTAL-CODE']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="text"
                                                            name="userPostalCode"
                                                            placeholder= {content['FORM.PERSONAL.INFO-POSTAL-CODE']} 
                                                            style={{ heigth: '250px'}}
                                                            currentValue={postalCode}
                                                            handleOnInputValue={(data) => { setPostalCode(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                <div className="row">
                                                    <div className = "col-12 col-md-4">
                                                        <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO-CITY']} *</strong></h4>
                                                    </div>
                                                    <div className = "col-12 col-md-6">
                                                        <InputForm
                                                            type="text"
                                                            name="Ville"
                                                            placeholder= {content['FORM.PERSONAL.INFO-CITY']}
                                                            style={{ heigth: '250px'}}
                                                            currentValue={city}
                                                            handleOnInputValue={(data) => { setCity(data)}}/>
                                                    </div>
                                                </div>
                                                <div className="py-1"></div>
                                                
                                                
                                            </div>
                                                <div className = "col-12 col-md-4 pt-2">
                                                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO-COUNTRY']} *</strong></h4>
                                                </div>
                                                <div className = "col-12 col-md-6 pt-2">
                                                    <DropDownCities
                                                    options = {listaPaysOptions}
                                                    selectedItem={country}
                                                    onSelect={(item)=>setCountry(item)}/>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className = "col-12 col-md-4 mt-3 pt-4">
                                                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO-PASSWORD']} *</strong></h4>
                                                </div>
                                                <div className = "col-12 col-md-6 mt-3 pt-4">
                                                    <InputForm
                                                        type="password"
                                                        name="password"
                                                        placeholder= {content['FORM.PERSONAL.INFO-PASSWORD']}
                                                        style={{ heigth: '250px'}}
                                                        currentValue={password}
                                                        handleOnInputValue={(data) => { 
                                                            setPassword(data)
                                                            const postalCodeRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$-_%^&*]{6,16}$/;
                                                            setIsValidUpdate(postalCodeRegex.test(data))}}/>
                                                </div>
                                                <div className = "col-12 col-md-4 pt-2">
                                                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.PERSONAL.INFO-CONFIRMATION']} *</strong></h4>
                                                </div>
                                                <div className = "col-12 col-md-6 pt-2">
                                                    <InputForm
                                                        type="password"
                                                        name="passwordConf"
                                                        placeholder= {content['FORM.PERSONAL.INFO-CONFIRMATION']}
                                                        style={{ heigth: '250px'}}
                                                        currentValue={passwordConfirm}
                                                        handleOnInputValue={(data) => { setPasswordConfirm(data)}}/>
                                                        {password.length ?
                                                            <div>
                                                                {!isValidUpdate && 
                                                                <div className='py-4'>
                                                                    <ValidationAlert message="The password must contain: / 6 - 16 characters and a capital letter / A number / A special character"/>
                                                                </div>}
                                                            </div>
                                                        :""
                                                        } 
                                                        <div className='py-2'>
                                                            
                                                        </div>
                                                        {password.length ?
                                                            <div>
                                                                {!isValidSamePassUpdate &&
                                                                <div className='py-4'>
                                                                    <ValidationAlert message="Passwords doesn't match"/> 
                                                                </div>}
                                                            </div>
                                                        :""
                                                    }
                                                </div>
                                            </div>
                                            
                                            
                                            
                                                <div className="row pt-5 pb-3">
                                                    <div className = "col-0 col-md-4"></div>
                                                    <div className = "col-12 col-md-6">
                                                        <div className="py-1"></div>
                                                        <Button 
                                                        message = {content['FORM.PERSONAL.INFO-BUTTON']}
                                                        onClick = {()=>updateInfo(sub)}/>                                                        
                                                    </div>
                                                </div>        
                                        </div>
                                        </div>
                                    
                                    </div>
                                    <div className="col-1 col-md-2"></div>
                                </div>
                            </div>
                        :
                        <div className="py-5">
                            <LoadingFacom/>
                        </div>
                        }
                    </div>
                
            </div>

        );
}   

export default PersonalInfoForm;