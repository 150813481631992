import React from 'react';
import '../../constants/global.css'
import NavMenu from '../atoms/NavMenu'
import NavMenuEnd from '../atoms/NavMenuEnd';
import 'bootstrap/dist/css/bootstrap.css'
import {Link} from 'react-router-dom'


import LogoFacom from '../../images/Facom_logo.png'
function HeaderMain (){
    return(
        <div className="container mainHeader">
            <div className="row" style={{paddingTop:'0 !important'}}>
                <div className="col-12 col-lg-2">
                    <Link to="/">
                        <img className="logoMainHeader" src={LogoFacom} alt="Logo Facom" ></img>
                    </Link>
                </div>
                <div className="col-12 col-lg-7" >
                    <NavMenu/>
                </div >
                <div className="col-12 col-lg-3">
                    <NavMenuEnd/>
                </div >
            </div>
        </div>
    );
}

export default HeaderMain;