import logo from './logo.svg';
import './App.css';
import AppConext from './context/AppContext';
import useglobalStates from './hooks/globalStates';
import MainComponent from './MainComponent'
import { Provider } from 'react-redux';
import { STORE as store,PERSISTOR} from './redux';
import { PersistGate } from 'redux-persist/integration/react';
import { setInterceptors } from './frameworks_and_drivers/Axios';

setInterceptors()

const App = () => { 
  
  const globalState = useglobalStates();
  const loggedIn = true;

  return (
    <AppConext.Provider value={globalState}>
       <Provider store={store}>
        <PersistGate persistor={PERSISTOR}>  
          <MainComponent loggedIn={loggedIn} />
        </PersistGate>
      </Provider>
    </AppConext.Provider>
  );
}

export default App;
