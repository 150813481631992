import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import './style/loginForm.css'
import Swal from 'sweetalert2'
import ButtonRed from '../atoms/Button';

import Button from 'react-bootstrap/Button'
import InputForm from '../atoms/InputForm';
import { generateNewPassword } from '../../api/facom_services'
import { Modal } from 'react-bootstrap'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORGOT-PASSWORD-BOX.LOGIN-HOME.TITLE" : "J'AI OUBLIÉ MON MOT DE PASSE",
    "FORGOT-PASSWORD-BOX.LOGIN-HOME.INPUT.EMAIL" : "E-mail",
    "LOGIN-HOME-FORGOT-PASSWORD-BOX.INPUT.EMAIL-PLACEHOLDER" : "Mon adresse e-mail",
    "FORGOT-PASSWORD-BOX.LOGIN-HOME.BUTTON" : "RÉINITIALISER MON MOT DE PASSE"

}

function RecoverPasswordForm(props){

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    const [emailRecover, setEmailRecover] = useState('')
   
    //Call api
    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password reset',
        })
    }
    const [newPassword, setNewPassword] = useState('')
    const resetPassword = async () =>{
        // setLoading(!loading)                   
            try{
                const response = generateNewPassword(emailRecover);  
                const data = (await response).data;
                setNewPassword(data.result?.generated_password)
        
                return(data)
            }catch(error){
                    console.log("error updated Password")
                    console.log(error)
                    alertWrongGet()
            }finally{
                // setLoading(false)
                console.log("end")
                setOpenSearchPassword(true)
                // window.location.reload()
            }  
        }
        const [openSearchPassword, setOpenSearchPassword] = React.useState(false);
        const showModalPassword = () => {
            return (
                <>
                    <Modal
                    show={openSearchPassword}
                    onHide={() => {
                        setOpenSearchPassword(false)
                    }}
                    backdrop="static"
                    keyboard={false}
                    >
                    <Modal.Header closeButton>
                        <Modal.Title>Resset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-lg-12 p-4">
                            <h4 className="h4-stl alet-warning alert">
                                <strong>your new password is: {newPassword}</strong>
                            </h4>
                        
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=> setOpenSearchPassword(false)} variant="success">Confirm</Button>
        
                    </Modal.Footer>
                    </Modal>
                </>
            );
        }
    

    return (
        <div className="py-5 px-4 Background_White">
            <h2 className="h2-stl  Center center__text">
                <div className='font__maxwidth'>    
                    <strong>{content['FORGOT-PASSWORD-BOX.LOGIN-HOME.TITLE']}</strong>
                </div>
            </h2>
            <div className="line-loggin"></div>
            <div className="row">
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORGOT-PASSWORD-BOX.LOGIN-HOME.INPUT.EMAIL']}</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <InputForm
                        type="text"
                        name="User"
                        placeholder={`${content['LOGIN-HOME-FORGOT-PASSWORD-BOX.INPUT.EMAIL-PLACEHOLDER']}`}
                        style={{ heigth: '250px'}}
                        currentValue={emailRecover}
                        handleOnInputValue={(data) => { setEmailRecover(data)}}/>
                    <div className="py-2"></div>
                    <ButtonRed
                    onClick = {()=>resetPassword()}
                    message = {`${content['FORGOT-PASSWORD-BOX.LOGIN-HOME.BUTTON']}`}/>
                </div>
                <div className = "col-0 col-md-2"></div>
            </div>
            {showModalPassword()}
        </div>

        );
};

export default RecoverPasswordForm;