import { SET_CONTENTS } from ".";

const Variants = {
  content: "",
  language: "",
  status: "",
};

const Contents = {
  placeholder: "",
  variants: [],
};

/**
 *
 * @param {Contents} payload
 * @returns
 */
const setContents = (payload) => (dispatch) => {
  dispatch({
    type: SET_CONTENTS,
    payload,
  });
};

export { setContents, Variants, Contents };
