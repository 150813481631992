import React, { useState, useRef, useContext,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import './style/loginForm.css'
import ButtonClean from '../atoms/ButtonClean';
import {Link} from 'react-router-dom'

import professionalImage from '../../images/Professionaljpg.jpeg'
import particularImage from '../../images/Particular.jpg'

import { useSelector } from 'react-redux';
import { setProfile,clearState } from '../../redux/actions/profile_actions'
import {STORE as store} from '../../redux/index'
import { useDispatch } from 'react-redux'
import { IRootState } from '../../redux';

import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "CREATE-BOX.LOGIN-HOME.TITLE" : "OU JE ME CRÉE UN COMPTE",
    "CREATE-BOX.LOGIN-HOME.PROFESSIONAL" : "Professionnel & artisan",
    "CREATE-BOX.LOGIN-HOME.PARTICULAR" : "PARTICULIER",
  } 
function CreateAccount(props){
    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
  
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
 
    return (
        <div className="py-5 px-4 Background_White">
            <h2 className="h2-stl Center "><strong>{content['CREATE-BOX.LOGIN-HOME.TITLE']}</strong></h2>
            <div className="line-loggin"></div>
            <div className="row">
                <div className="col-1"></div>
                <div className="col-12 col-md-5 mx-1 mt-3" style={{padding:'0',margin:'0 -10px'}}>
                    <img src={professionalImage} style={{width:'100%'}}/>
                    <Link to="/register/professional">
                        <ButtonClean
                        message = {`${content['CREATE-BOX.LOGIN-HOME.PROFESSIONAL']}`}
                        width= "100%"/>
                    </Link>
                </div>
            
                <div className="col-12 col-md-5 mx-1 mt-3" style={{padding:'0',margin:'0'}} >
                    <img src={particularImage} style={{width:'100%'}}/>
                    <Link to="/register/individual">
                        <ButtonClean
                        message = {`${content['CREATE-BOX.LOGIN-HOME.PARTICULAR']}`}
                        width= "100%"
                        />
                    </Link>
                </div>
                <div className="col-1"></div>
                <div className = "col-0 col-md-2"></div>
            </div>
            
        </div>

        );
};

export default CreateAccount;