import React, { useRef, useState, useEffect, useContext } from 'react';
import debounce from 'lodash.debounce';
import { Item, Menu, Label, Field, Dropdown, Autocomplete } from '@zendeskgarden/react-dropdowns';
import { Row, Col } from '@zendeskgarden/react-grid';
import AppConext from '../../context/AppContext';


const DropDownProduct= (props) => {
    // const [selectedItem, setSelectedItem] = useState(props.options[0]);
    const [inputValue, setInputValue] = useState('');
    const {setProductID}= useContext(AppConext);

    return (
        <Row justifyContent="left">
        <Col sm={12}>
            <Dropdown
            inputValue={inputValue}
            selectedItem={props.selectedItem}
            onSelect={props.onSelect}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
            >
            <Field>
                <Autocomplete
                style = {{
                    color:  '#2BE17B',
                    borderRadius:'2px',
                    textAlign:'left',
                    fontFamily: 'Arial',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    width:'100%',
                    height:'30px',
                    border:'1px solid #ced4da'
                    }}>{props.selectedItem}</Autocomplete>
            </Field>
            <Menu style = {{backgroundColor: '#FFF',
                    color: '#818181',
                    borderRadius:'2px',
                    fontFamily: 'Arial',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '16px',
                    zIndex:'1'

                    }}>
                {props.options?.length ? (
                props.options.map(option => (
                    <Item key={option.reference} value={option.reference} onClick={()=>setProductID(option.id)}>
                    <span>{option.reference}</span>
                    </Item>
                ))
                ) : (
                <Item disabled>No matches found</Item>
                )}
            </Menu>
            </Dropdown>
        </Col>
        </Row>
    );
};

export default DropDownProduct