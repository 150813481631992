import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import '../molecules/style/loginForm.css'
import InputForm from './InputForm';
import ValidationAlert from './ValidationAlert';

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.REGISTER-PROFESSIONAL.POSTAL-CODE"	:" Code Postal",
    "FORM.REGISTER-PROFESSIONAL.SIRET"	:" Siret",
}
function FormProfessionalUser(){
    const {zipCode,setZipCode,siretCode,setSiretCode, isValidSiret, setIsValidSiret} = useContext(AppConext)

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    return (
        <div className="Background_White">
            <div className="row">
                {/* Falta sacar lo que se escribe*/}
                <div className = "col-12 col-md-4 pt-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content["FORM.REGISTER-PROFESSIONAL.POSTAL-CODE"]} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-4">
                    <InputForm
                        type="text"
                        name="Code Postal"
                        placeholder= {content["FORM.REGISTER-PROFESSIONAL.POSTAL-CODE"]}
                        style={{ heigth: '250px'}}
                        currentValue={zipCode}
                        handleOnInputValue={(data) => { setZipCode(data)}}/>
                </div>
                <div className = "col-12 col-md-4 pt-2">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.SIRET']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-2">
                    <InputForm
                        type="text"
                        name="Numéro SIRET"
                        placeholder= {content['FORM.REGISTER-PROFESSIONAL.SIRET']} 
                        style={{ heigth: '250px'}}
                        maxLength = {10}
                        currentValue={siretCode}
                        handleOnInputValue={(data) => { 
                            setSiretCode(data)
                            const postalCodeRegex =  /^([0-9]{10})?$/;
                            setIsValidSiret(postalCodeRegex.test(data))}}/>

                        {siretCode.length ?
                            <div>
                                {!isValidSiret &&
                                <div className='py-4'>
                                    <ValidationAlert message="It must be a 10 digit number"/> 
                                </div>}
                            </div>
                        :""
                        }
                </div>
            </div>
        </div>

        );
};

export default FormProfessionalUser;