import React, {useState, useEffect}from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './styles/tabWarranty.css'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM-NEW-WARRANTY-ALL.TAB-PRODUCT":"1. TYPOLOGIE DU PRODUIT",
    "FORM-NEW-WARRANTY-ALL.TAB-PICTURES":"2. PHOTOS",
    "FORM-NEW-WARRANTY-ALL.TAB-TYPE":"3. TYPE DE CASSE",
    "FORM-NEW-WARRANTY-ALL.TAB-SUBMIT":"4. SOUMETTRE",
}
function useWindowSize(){
    const[windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        function handleResize(){
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize',handleResize);
        handleResize()
        return () => window.removeEventListener('resize',handleResize)
    },[])
    return windowSize
    }
const TabWarranty = (props) => {

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const {width,height} = useWindowSize();
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    const renderTabTypologie = ()=>{
        return(
            <div className="row pt-5" style={{marginLeft:'0 !important'}}>
                <div className="col-1"></div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-12 col-lg-4 red-back ">
                            <h4 className="textTabsred "><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PRODUCT']}</strong></h4>
                            {width > 450 && <div className="arrow"> </div>}
                        </div>
                        <div className="col-12 col-lg-2 gray-back ">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PICTURES']}</strong></h4>
                        </div>
                        <div className="col-12 col-lg-3 gray-back ">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-TYPE']}</strong></h4>
                        </div>
                        <div className="col-12 col-lg-3 gray-back ">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-SUBMIT']}</strong></h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderTabPhotos = ()=>{
        return(
            <div className="row pt-5" style={{marginLeft:'0 !important'}}>
                <div className="col-1"></div>
                    <div className="col-10">
                        <div className="row">
                            <div className="col-12 col-lg-4 gray-back">
                                <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PRODUCT']}</strong></h4>
                                {width > 450 && <div className="arrow2"></div>}
                                
                            </div>
                            <div className="col-12 col-lg-2 red-back ">
                                <h4 className="textTabsred"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PICTURES']}</strong></h4>
                            </div>
                            <div className="col-12 col-lg-3 gray-back ">
                                <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-TYPE']}</strong></h4>
                            </div>
                            <div className="col-12 col-lg-3 gray-back ">
                                <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-SUBMIT']}</strong></h4>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

    const renderTabTypeCasse = ()=>{
        return(
            <div className="row pt-5" style={{marginLeft:'0 !important'}}>
                <div className="col-1"></div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-12 col-lg-4 gray-back">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PRODUCT']}</strong></h4>
                            {width > 450 && <div className="arrow3"></div>}
                        </div>
                        <div className="col-12 col-lg-2 gray-back">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PICTURES']}</strong></h4>
                        </div>
                        <div className="col-12 col-lg-3 red-back ">
                            <h4 className="textTabsred"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-TYPE']}</strong></h4>
                        </div>
                        <div className="col-12 col-lg-3 gray-back ">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-SUBMIT']}</strong></h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderTabSend = ()=>{
        return(
            <div className="row pt-5" style={{marginLeft:'0 !important'}}>
                <div className="col-1"></div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-12 col-lg-4 gray-back">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PRODUCT']}</strong></h4>
                            {width > 450 && <div className="arrow4"></div>}
                        </div>
                        <div className="col-12 col-lg-2 gray-back">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-PICTURES']}</strong></h4>
                        </div>
                        <div className="col-12 col-lg-3 gray-back ">
                            <h4 className="textTabs"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-TYPE']}</strong></h4>
                        </div>
                        <div className="col-12 col-lg-3 red-back ">
                            <h4 className="textTabsred"><strong>{content['FORM-NEW-WARRANTY-ALL.TAB-SUBMIT']}</strong></h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div>
            {props.pageStatus === 2 && renderTabTypologie()}
            {props.pageStatus === 3 && renderTabPhotos()}
            {props.pageStatus === 4 && renderTabTypeCasse()}
            {props.pageStatus === 5 && renderTabSend()}
        </div>
    );
};

export default TabWarranty