import React, { useState, useRef, useContext, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../context/AppContext';

// COMPONENTS

import { Link } from 'react-router-dom';
import {  getAllExchangesByStatusAndID } from '../api/facom_services'
import Swal from 'sweetalert2'
// CSS
import '../constants/global.css'
import './styles/tranking.css'

import TableState from '../components/atoms/Table';
import LoadingFacom from '../components/atoms/LoadingFacom';
import { useSelector,useDispatch } from 'react-redux';
import {getAllUsersById} from '../api/facom_services'

//contents
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "TABLE-TRACKING-WARRANTY.TITLE":	"SUIVRE MES DEMANDES DE PRISE DE GARANTIE",
    "TABLE-TRACKING-WARRANTY.TITLE-DRAFT":	"DEMANDES EN ÉTAT : BROUILLON",
    "TABLE-TRACKING-WARRANTY.TITLE-ABANDONNED":	"DEMANDES EN ÉTAT : ABANDONNÉ",
    "TABLE-TRACKING-WARRANTY.TITLE-SUBMITTED":	"DEMANDES EN ÉTAT : SOUMIS",
    "TABLE-TRACKING-WARRANTY.TITLE-ANALYSIS":	"DEMANDES EN ÉTAT : ANALYSE",
    "TABLE-TRACKING-WARRANTY.TITLE-ACCEPTED":	"DEMANDES EN ÉTAT : ACCEPTÉES",
    "TABLE-TRACKING-WARRANTY.TITLE-REFUSED":	"DEMANDES EN ÉTAT : REFUSÉ",
    "TABLE-TRACKING-WARRANTY.TITLE-AVAILABLE":	"DEMANDES EN ÉTAT : DISPONIBLE",
    "TABLE-TRACKING-WARRANTY.TITLE-EXCHANGED":	"DEMANDES EN ÉTAT : ECHANGÉ",
    "TABLE-TRACKING-WARRANTY.TITLE-RECYCLED":	"DEMANDES EN ÉTAT : RECYCLÉ",

    "FORM.ALL.BACK":"Retour au tableau de bord"
}
function TrackingState(props){

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    const { sub } = profile
    console.log(sub)

    const {version}= useContext(AppConext);
    const list =[
        {
            name:'camilo',
            lastName: 'andres',
            date: '11/23/45',
            status:'new'
        },
        {
            name:'andres', 
            lastName: 'londres',
            date: '13/23/45',
            status:'revised'
        }
    ]
    //const State = ""
    const State = "Borrador"

    const arrowLeft="< "
     //Call api
     const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }


    const [loading,setLoading] = useState(false)
    const [groupExchangesDraft,setGroupExchangesDraft] = useState([])
    const [groupExchangesSubmitted,setGroupExchangesSubmitted] = useState([])    
    const [groupExchangesAbandonned,setGroupExchangesAbandonned] = useState([])
    const [groupExchangesAnalysis,setGroupExchangesAnalysis] = useState([])
    const [groupExchangesAccepted,setGroupExchangesAccepted] = useState([])
    const [groupExchangesAvailable,setGroupExchangesAvailable] = useState([])
    const [groupExchangesExchanged,setGroupExchangesExchanged] = useState([])
    const [groupExchangesRefused,setGroupExchangesRefused] = useState([])
    const [groupExchangesRecycled,setGroupExchangesRecycled] = useState([])


    
    useEffect(() => {
        getExchangesStatus();
    }, [])
    const getExchangesStatus = async () => {
        setLoading(!loading)

        try{
            const response = getAllUsersById(sub);  
            const data = (await response).data;
            if(data?.result?.customer_data?._id){

                const response = getAllExchangesByStatusAndID("Draft",data?.result?.customer_data?._id);
                const data0 = (await response).data;
                setGroupExchangesDraft(data0)
        
                const response2 = getAllExchangesByStatusAndID("Submitted",data?.result?.customer_data?._id);
                const data2 = (await response2).data;
                setGroupExchangesSubmitted(data2)
        
                const response3 = getAllExchangesByStatusAndID("Abandonned",data?.result?.customer_data?._id);
                const data3 = (await response3).data;
                setGroupExchangesAbandonned(data3)
        
                const response4 = getAllExchangesByStatusAndID("Analysis",data?.result?.customer_data?._id);
                const data4 = (await response4).data;
                setGroupExchangesAnalysis(data4)
        
                const response5 = getAllExchangesByStatusAndID("Accepted",data?.result?.customer_data?._id);
                const data5 = (await response5).data;
                setGroupExchangesAccepted(data5)
        
                const response6 = getAllExchangesByStatusAndID("Available",data?.result?.customer_data?._id);
                const data6 = (await response6).data;
                setGroupExchangesAvailable(data6)
        
                const response7 = getAllExchangesByStatusAndID("Exchanged",data?.result?.customer_data?._id);
                const data7 = (await response7).data;
                setGroupExchangesExchanged(data7)
        
                const response8 = getAllExchangesByStatusAndID("Refused",data?.result?.customer_data?._id);
                const data8 = (await response8).data;
                setGroupExchangesRefused(data8)
        
                const response9 = getAllExchangesByStatusAndID("Recycled",data?.result?.customer_data?._id);
                const data9 = (await response9).data;
                setGroupExchangesRecycled(data9)
            }
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  

}

    return (
        <div >
            <div>
                    <div className="row Background_Gray py-5"  style={{margin:'0'}}>
                    <Link to="/">
                        <h6 className="h6-return">{arrowLeft} {content['FORM.ALL.BACK']}</h6>
                    </Link>
                    <div className="row px-3">
                    <h2 className="h1-stl Center py-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE']}</strong></h2>
                        <div className="col-0 col-lg-2"></div>
                            {!loading ? "" : <div className='py-5'> <LoadingFacom/></div>}
                        {!loading &&
                        <div className="col-12 col-lg-8 Background_White pt-5" style={{textAlign:'center'}}>
                        <div className="row px-3">
                            <div className="col-12 col-lg-1"></div>
                            <div className="col-12 col-lg-10">
                                <div>
                                    {groupExchangesDraft?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-DRAFT']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesDraft?.result?.items}
                                            State={"DRAFT"}
                                            reload = {()=>getExchangesStatus()}/>
                                        </div>
                                    }
                                    {groupExchangesSubmitted?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-SUBMITTED']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesSubmitted?.result?.items}
                                            State="SOUMIS"/>
                                        </div>
                                    }
                                    {groupExchangesAbandonned?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-ABANDONNED']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesAbandonned?.result?.items}
                                            State="SOUMIS"/>
                                        </div>
                                    }   
                                    {groupExchangesAnalysis?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-ANALYSIS']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesAnalysis?.result?.items}
                                            State="ANALYSE"/>
                                        </div>
                                    } 
                                    {groupExchangesAccepted?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-ACCEPTED']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesAccepted?.result?.items}
                                            State="ACCEPTÉ"/>
                                        </div>
                                    }
                                    {groupExchangesAvailable?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-AVAILABLE']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesAvailable?.result?.items}
                                            State="ACCEPTÉ"/>
                                        </div>
                                    }
                                    {groupExchangesExchanged?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-EXCHANGED']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesExchanged?.result?.items}
                                            State="ACCEPTÉ"/>
                                        </div>
                                    }
                                    {groupExchangesRefused?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-REFUSED']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesRefused?.result?.items}
                                            State="REFUSÉ"/>                    
                                        </div>
                                    }
                                    {groupExchangesRecycled?.result?.items.length != 0 &&
                                        <div>
                                            <h2 className="h2-stl-Track pt-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-RECYCLED']}</strong></h2>
                                            <div className="historic-list"></div>
                                            <TableState
                                            list={groupExchangesRecycled?.result?.items}
                                            State="REFUSÉ"/>                                    
                                        </div>
                                    }
                                    <div className="py-5"></div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-1"></div>
                        </div>
                        </div>
                        }
                        <div className="col-1 col-lg-2"></div>
                    </div>
                </div>
                </div>
            </div>
        );
};

export default TrackingState;
