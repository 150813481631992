import React, { useState, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import '../../constants/global.css'
import './styles/buttonClear.css'

function ButtonClean(props){
    return (
        <div>
            <button disabled={props.disabled}  type="button" style = {{width:`${props.width}`,display:'flex', float:`${props.float}`,maxWidth:`${props.maxWidth}`,}} onClick={props.onClick} className="btn-red-cl pt-2"><span>{props.message}</span></button>
        </div>
        );
};

export default ButtonClean;