import React from 'react';
import './styles/input.css'
import './styles/input.css'

function InputForm (props){
    const handleOnInputChange = (event)=>{
        const cliendData = event.target.value;
        props.handleOnInputValue(cliendData)
    }  
    return(
        <div>
            {/* <form action =""> */}
                <input className="form-control"
                    type= {props.type} 
                    onKeyDown={props.onKeyDown}
                    name = {props.name} 
                    maxLength={props.maxLength}
                    placeholder = {props.placeholder}
                    style = {props.style}
                    onChange = {(value) =>handleOnInputChange(value)}
                    value = {props.currentValue}
                    readOnly = {props.readOnly}
                    >
                    
                </input>
            {/* </form>      */}
        </div>
    );
}
export default InputForm;