import React from 'react';
import Alert from '@material-ui/lab/Alert';

import './styles/ValidationAlert.css'

export default function ValidationAlert(props) {

    return (
        <div className="alert-stl" >
            <Alert variant="outlined" severity="error" >
                {props.message}
            </Alert>
        </div>
  );
}