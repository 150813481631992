import React, { useState, useRef, useContext, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../context/AppContext';
import './styles/details.css'
// COMPONENTS

import FooterLogin from '../components/molecules/FooterLogin';
import HeaderMain from '../components/molecules/HeaderMain';
import { Link } from 'react-router-dom';
import { getAllExchangesByStatus } from '../api/facom_services'
import Swal from 'sweetalert2'
// CSS
import '../constants/global.css'
import './styles/tranking.css'

import TableState from '../components/atoms/Table';
import facomLogo from '../images/Facom_logo.png'
import houseIcon from '../images/house.svg'
import { getAllExchangesById } from '../api/facom_services'
import CircleRed from '../images/CircleRed.svg'
import LoadingFacom from '../components/atoms/LoadingFacom';

//contents
import { useSelector } from 'react-redux';
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "TABLE-TRACKING-WARRANTY.TITLE-DETAIL":	"DÉTAILS",
    "TABLE-TRACKING-WARRANTY.SUBMITTED-PART-ONE":	"Demande reçue le ",
    "TABLE-TRACKING-WARRANTY.SUBMITTED-PART-TWO":	"par les équipes FACOM Garantie à vie.",
    "TABLE-TRACKING-WARRANTY.ANALYSIS-PART-ONE":	"Le ",
    "TABLE-TRACKING-WARRANTY.ANALYSIS-PART-TWO":	"En cours d'analyse par les équipes FACOM.",
    "TABLE-TRACKING-WARRANTY.ACCEPTED-PART-ONE":	"Le",
    "TABLE-TRACKING-WARRANTY.ACCEPTED-PART-TWO":	"Demande acceptée par les équipes FACOM Garantie à vie.",
    "TABLE-TRACKING-WARRANTY.REFUSED-PART-ONE":	"Le",
    "TABLE-TRACKING-WARRANTY.REFUSED-PART-TWO":	"L'équipe FACOM n'a pas acceptée la garantie à vie pour la raison suivante: Outillage incomplet. Rendez-vous chez votre distributeur",
    "TABLE-TRACKING-WARRANTY.REFUSED-PART-THREE":	"pour une analyse complémentaire de votre produit à l'adresse suivante : Rue Mouffetard, 75005 Paris, France.",
    "TABLE-TRACKING-WARRANTY.AVAILABLE-PART-ONE":	"Le",
    "TABLE-TRACKING-WARRANTY.AVAILABLE-PART-TWO":	"Votre nouveau produit est disponible chez",
    "TABLE-TRACKING-WARRANTY.AVAILABLE-PART-THREE":	"Vous pouvez le retirer en magasin à l'adresse suivante:",
    "TABLE-TRACKING-WARRANTY.DRAFT-PART-ONE": 	"-",
    "TABLE-TRACKING-WARRANTY.ABANDONNED-PART-ONE":	"-",
    "TABLE-TRACKING-WARRANTY.EXCHANGED-NOT-QUALIFIED":	"Pour nous aider à améliorer notre service, pouvez vous nous donner votre avis sur votre expérience avec notre distributeur partenaire ",
    "TABLE-TRACKING-WARRANTY.EXCHANGED-QUALIFIED":	"Le ",
    "TABLE-TRACKING-WARRANTY.EXCHANGED-QUALIFIED-TWO":	"Vous avez retiré votre nouveau produit chez",
    "TABLE-TRACKING-WARRANTY.RECYCLED-PART-ONE":	"-",
    "FORM.ALL.BACK" : "Retour au tableau de bord",
}

function DetailsExchange(props){
    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    //const State = ""
    const State = "Borrador"

    const arrowLeft="< "

     //Call api
     const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password reset',
        })
    }
    const [loading, setLoading] = useState(false)
    const [detailsArray, setDetailsArray] = useState([])

    useEffect(() => {
        getExchangesByID(props.id)
    }, [])

    const getExchangesByID = async (ExchangeId) =>{
        setLoading(!loading)
        try{
            const response = getAllExchangesById(ExchangeId);  
            const data = (await response).data;
            setDetailsArray(data)
            return(data)
        }catch(error){
            console.log("error get details")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }   
    }

    return (
        
        <div >
                

                    <div className="row Background_Gray py-5"  style={{margin:'0'}}>
                    <Link to="/historic">
                        <h6 className="h6-return">{arrowLeft} {content['FORM.ALL.BACK']}</h6>
                    </Link>
                    
                    <div className="row">
                    <h2 className="h1-stl Center py-5"><strong>{content['TABLE-TRACKING-WARRANTY.TITLE-DETAIL']}</strong></h2>
                        <div className="col-1 col-lg-3"></div>
                        {!loading ? "" : <div className='py-5'> <LoadingFacom/></div>}
                        {!loading && 
                            <div className="col-11 col-lg-6 Background_White pt-5" >
                            <div className="row">
                                <div className="col-12 col-lg-1"></div>
                                <div className="col-12 col-lg-10">  
                                {detailsArray?.result?.status?.map((row)=>(
                                    <div className='py-3' >
                                        <div style={{display:'flex'}}>
                                            {row.status === "Draft" && ""}
                                            {row.status === "Submitted" &&
                                                <div style={{display:'flex'}}>
                                                    <img src ={CircleRed}></img>
                                                    <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}>{content['TABLE-TRACKING-WARRANTY.SUBMITTED-PART-ONE']} {row.createdAt} {content['TABLE-TRACKING-WARRANTY.SUBMITTED-PART-TWO']}</h5>
                                                </div>
                                            }
                                            {row.status === "Analysis" &&
                                                <div style={{display:'flex'}}>
                                                    <img src ={CircleRed}></img>
                                                    <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}>{content['TABLE-TRACKING-WARRANTY.ANALYSIS-PART-ONE']}  {row.createdAt} : {content['TABLE-TRACKING-WARRANTY.ANALYSIS-PART-TWO']}</h5>
                                                </div>
                                            }
                                            {row.status === "Accepted" &&
                                                <div style={{display:'flex'}}>
                                                    <img src ={CircleRed}></img>
                                                    <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}>{content['TABLE-TRACKING-WARRANTY.ACCEPTED-PART-ONE']} {row.createdAt} : {content['TABLE-TRACKING-WARRANTY.ACCEPTED-PART-TWO']}</h5>
                                                </div>
                                            }
                                             {row.status === "Available" &&
                                                <div style={{display:'flex'}}>
                                                    <img src ={CircleRed}></img>
                                                    <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}>{content['TABLE-TRACKING-WARRANTY.AVAILABLE-PART-ONE']} {row.createdAt} :{content['TABLE-TRACKING-WARRANTY.AVAILABLE-PART-TWO']} {detailsArray?.result?.user_data?.username}. {content['TABLE-TRACKING-WARRANTY.AVAILABLE-PART-THREE']} :{detailsArray?.result?.distributor_data?.address?.address}</h5>
                                                </div>
                                            } 
                                            {row.status === "Exchanged" &&
                                                <div style={{display:'flex'}}>
                                                    {detailsArray?.result?.distributor_data?.evaluation === 0 &&
                                                        <div style={{display:'flex'}}>
                                                            <img src ={CircleRed}></img>
                                                            <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}> {content['TABLE-TRACKING-WARRANTY.EXCHANGED-NOT-QUALIFIED']} ? </h5>
                                                        </div>
                                                    }
                                                    {detailsArray?.result?.distributor_data?.evaluation !== 0 &&
                                                        <div style={{display:'flex'}}>
                                                            <img src ={CircleRed}></img>
                                                            <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}>{content['TABLE-TRACKING-WARRANTY.EXCHANGED-QUALIFIED-TWO']} {detailsArray?.result?.distributor_data?.name}  + {detailsArray?.result?.distributor_data?.evaluation}</h5>
                                                        </div>
                                                    }
                                                 
                                                </div>
                                            } 
                                            {row.status === "Refused" &&
                                                <div style={{display:'flex'}}>
                                                    <img src ={CircleRed}></img>
                                                    <h5 style={{fontSize:'12px',paddingLeft:'10px',paddingTop:'10px'}}>{content['TABLE-TRACKING-WARRANTY.REFUSED-PART-ONE']} {row.createdAt} :{content['TABLE-TRACKING-WARRANTY.REFUSED-PART-TWO']} {detailsArray?.result?.refusal_reason_data?.label} {content['TABLE-TRACKING-WARRANTY.REFUSED-PART-THREE']} : {detailsArray?.result?.distributor_data?.address?.address}.</h5>
                                                </div>
                                            } 

                                        </div>
                                    </div>
                                ))}
                    
                                </div>
                                <div className="col-12 col-lg-1"></div>
                            
                            </div>
                            <div className='py-3'>
                                
                            </div>
                            
                            </div>
                        }
                        <div className="col-1 col-lg-2"></div>
                    </div>
                </div>
            </div>
            
        );
};

export default DetailsExchange;
