import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import '../molecules/style/loginForm.css'

import InputForm from './InputForm';

//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.REGISTER-PROFESSIONAL.FIRST-NAME" : "Prénom",
    "FORM.REGISTER-PROFESSIONAL.LAST-NAME" : "Nom",
    "FORM.REGISTER-PROFESSIONAL.PHONE" : "Tel",
}
function FormInitialUser(props){

    const {firstName, setFirstName,lastName, setLastName,email, setEmail,mobilePhone, setMobilePhone,} = useContext(AppConext)

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    return (
        <div className="Background_White">
            <div className="row">
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.FIRST-NAME']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <InputForm
                        type="text"
                        name="Prénom"
                        placeholder= {content['FORM.REGISTER-PROFESSIONAL.FIRST-NAME']}
                        style={{ heigth: '250px'}}
                        currentValue={firstName}
                        handleOnInputValue={(data) => { setFirstName(data)}}/>
                </div>
            </div>
            <div className="py-1"></div>
            <div className="row">
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.LAST-NAME']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <InputForm
                        type="text"
                        name="Nom"
                        placeholder= {content['FORM.REGISTER-PROFESSIONAL.LAST-NAME']}
                        style={{ heigth: '250px'}}
                        currentValue={lastName}
                        handleOnInputValue={(data) => { setLastName(data)}}/>
                </div>
            </div>
            <div className="py-1"></div>
            <div className="row">
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>Email *</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <InputForm
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        style={{ heigth: '250px'}}
                        currentValue={email}
                        handleOnInputValue={(data) => { setEmail(data)}}/>
                </div>
            </div>
            <div className="py-1"></div>
            <div className="row">
                <div className = "col-12 col-md-4">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.PHONE']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6">
                    <InputForm
                        type="number"
                        name="Teléphone mobile"
                        placeholder= {content['FORM.REGISTER-PROFESSIONAL.PHONE']}
                        style={{ heigth: '250px'}}
                        currentValue={mobilePhone}
                        handleOnInputValue={(data) => { setMobilePhone(data)}}/>
                </div>
            </div>
            
        </div>

        );
};

export default FormInitialUser;