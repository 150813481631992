import React, {useContext, useState,useRef,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../context/AppContext';

// COMPONENTS

import {Link,useHistory} from 'react-router-dom'
import ButtonClean from '../components/atoms/ButtonClean';
import DropDownDistributor from '../components/molecules/DropDownDistributor';
import TabWarranty from '../components/atoms/TabWarranty';
import FileAttachement from '../components/atoms/FileAttachement';
import DropDownProduct from '../components/molecules/DropDownProduct';
import DropDownReasonEx from '../components/molecules/DropDownReasonEx';
import DropDownSKU from '../components/molecules/DropDownSKU';
import Swal from 'sweetalert2'
// CSS
import '../constants/global.css'
import './styles/warranty.css'
import Example1 from '../images/Example1.jpeg'
import Example2 from '../images/Example2.jpeg'
import Example3 from '../images/Example3.jpeg'
import Example4 from '../images/Example4.jpeg'
import TextArea from '../components/atoms/TextArea';
import { getAllProducts, getAllReasonExchanges, getAllProductsByReference,createExchange,getAllUsersById,getAllProductsByReferenceDD2} from '../api/facom_services'
import { getAllDistributorName, getAllDistributorPostalCode, getDistributorsById,updateExchanges,updateExchangesStatus, getAllDistributors } from '../api/facom_services'
import LoadingPag from '../components/atoms/LoadingPag';
import LoadingFacom from '../components/atoms/LoadingFacom';
import { useSelector,useDispatch } from 'react-redux';
//contents
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';

let Template = {
    "FORM-NEW-WARRANTY-ALL-TITLE":	"RÉALISER UNE NOUVELLE DEMANDE DE PRISE SOUS GARANTIE À VIE",
    "FORM-NEW-WARRANTY-INFO.STEP-TITLE":	"Nous allons vous guider à travers un processus en 4 étapes afin de pouvoir étudier votre demande",
    "FORM-NEW-WARRANTY-INFO.STEP-BOX-TITLE":	"RAPPEL DES CONDITIONS DE GARANTIES FACOM",
    "FORM-NEW-WARRANTY-INFO.STEP-INFO-TITLE-1":	"Garantie à Vie :",
    "FORM-NEW-WARRANTY-INFO.STEP-INFO-TITLE-2":	"Garantie limitée dans le temps :",
    "FORM-NEW-WARRANTY-INFO.STEP-INFO-TEXT-1":	"Sans aucune limitation dans le temps, l’outil sera échangé (garantie E) ou réparé / échangé par un outil réparé d’état similaire (Garantie R) gracieusement, en accord avec les conditions de mise en œuvre et d’application de la garantie.",
    "FORM-NEW-WARRANTY-INFO.STEP-INFO-TEXT-2":	"Sa durée est précisée par un chiffre après la lettre D (Garantie D2 – D3 – D5). Cette garantie concerne principalement des produits techniques, les servantes, le mobilier et la dynamométrie. Pendant sa période d’application, cette garantie couvre la réparation et le remplacement gracieux des pièces défectueuses, en accord avec les conditions de mise en œuvre et d’application de la garantie, à l’exclusion des pièces d’usures notamment).",
    "FORM-NEW-WARRANTY-INFO.STEP-INFO-DETAILS":	"Détails sur les conditions Garantie, consultez le Guide de la Garantie FACOM",
    "FORM-NEW-WARRANTY-INFO.STEP-INFO-BUTTON":	"COMMENCER",
    "FORM-NEW-WARRANTY-ALL.TAB-PRODUCT":	"1. TYPOLOGIE DU PRODUIT",
    "FORM-NEW-WARRANTY-ALL.TAB-PICTURES":	"2. PHOTOS",
    "FORM-NEW-WARRANTY-ALL.TAB-TYPE":	"3. TYPE DE CASSE",
    "FORM-NEW-WARRANTY-ALL.TAB-SUBMIT":	"4. SOUMETTRE",
    "FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-TITLE":	"Vous connaissez la référence produit :",
    "FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-PLACEHOLDER":	"Tapez la référence",
    "FORM-NEW-WARRANTY-FIRST.STEP-OR":	"Ou",
    "FORM-NEW-WARRANTY-FIRST.STEP-INPUT-DONT-KNOW-TITLE":	"Vous ne connaissez pas la référence produit :",
    "FORM-NEW-WARRANTY-FIRST.STEP-DONT-INPUT-KNOW-PLACEHOLDER":	"Type de produit (exemple : clé à molette)",
    "FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE":	"PRÉCÉDENT",
    "FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING":	"SUIVANT",
    "FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-1":	"Photo n ° 1",
    "FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-2":	"Photo n ° 2",
    "FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-3":	"Photo n ° 3",
    "FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-4":	"Photo n ° 4",
    "FORM-NEW-WARRANTY-SECOND.STEP-P1-TITLE-INFO-1":"Photo n°1",
    "FORM-NEW-WARRANTY-SECOND.P1-STEP-INFO-1":"	Veuillez prendre en photo la marque apparente sur le produit. Celle ci doit être bien visible. Attention aux photos floues.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P1-TITLE-INFO-2":"Conseils",
    "FORM-NEW-WARRANTY-SECOND.STEP-P1-INFO-2":"	Il est conseillé de prendre votre photo sur un fond clair.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P1-INFO-2.1":"La marque doit être bien visible et lisible sur la photo.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P1-INFO-2.2":"Attention aux reflets de lumière qui peuvent gêner la lisibilité.",
    "FORM-NEW-WARRANTY-ALL-TITLE-EXAMPLE":"	Exemple",
    "FORM-NEW-WARRANTY-SECOND.STEP-P2-TITLE-INFO-1":"Photo n°2",
    "FORM-NEW-WARRANTY-SECOND.P2-STEP-INFO-1":"	Le produit dans son ensemble. Son état doit être bien visible, attention aux photos floues.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P2-TITLE-INFO-2":"Conseils",
    "FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2":"	Il est conseillé de prendre votre photo sur un fond clair.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2.1":"L'outil doit être intégralement lisible sur la photo.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2.2":"Attention aux reflets de lumière qui peuvent gêner la lisibilité.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P3-TITLE-INFO-1":"Photo n°3",
    "FORM-NEW-WARRANTY-SECOND.P3-STEP-INFO-1":"	Prenez en photo la partie détériorée. Son état doit être bien visible, attention aux photos floues.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P3-TITLE-INFO-2":"Conseils",
    "FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2":"	Il est conseillé de prendre votre photo sur un fond clair.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2.1":"Prenez en photo la partie abîmée de manière à ce que nos équipes puissent constater les dégâts. Si l'outil est",
    "FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2.2":"cassé et qu'un bout est parti, faites le apparaître sur la photo si vous l'avez toujours.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2.3":"Attention aux reflets de lumière qui peuvent gêner la lisibilité.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P4-TITLE-INFO-1":"Photo n°4",
    "FORM-NEW-WARRANTY-SECOND.P4-STEP-INFO-1":"Prenez en photo la référence produit si visible.",
    "FORM-NEW-WARRANTY-SECOND.STEP-P4-TITLE-INFO-2":"Conseils",
    "FORM-NEW-WARRANTY-SECOND.STEP-P4-INFO-2":"	Si la référence produit est toujours visible sur l'outil, nous vous demandons de la joindre aux photos précédentes. ",
    "FORM-NEW-WARRANTY-SECOND.STEP-P4-INFO-2.1":"Cette photo n'est pas obligatoire mais elle est recommandée",
    "FORM-NEW-WARRANTY-THIRD.STEP-INPUT-KNOW-TITLE":"Si vous pouvez préciser le type de casse choisissez dans la liste ci-dessous :",
    "FORM-NEW-WARRANTY-THIRD.STEP-INPUT-KNOW-PLACEHOLDERS":"Selectionner",
    "FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-TITLE":"Pouvez vous nous apporter quelques précisions ?",
    "FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-PLACEHOLDER":"	Descriptif du problème constaté",
    "FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-INFO":"600 caractères restants",
    "FORM-NEW-WARRANTY-FINAL.STEP-TITLE":"FINALISER MA DEMANDE",
    "FORM-NEW-WARRANTY-FINAL.STEP-INFO":"Suite aux éléments que vous nous avez fournis, vous semblez être éligible à la garantie à vie FACOM. ",
    "FORM-NEW-WARRANTY-FINAL.STEP-INFO-2":"	Choisissez votre distributeur pour réception de votre nouvel outil en magasin. L'application est en cours de déploiement avec nos distributeurs partenaires FACOM, vous en trouverez un proche de chez vous très bientôt, si ce n'est pas déjà le cas. Merci pour votre patience.",
    "FORM-NEW-WARRANTY-FINAL.STEP-PLACEHOLDER":"Renseignez un code postal ou",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SUBMIT":"SOUMETRE",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-CREATE":"SOUMETTRE ET CRÉER UNE NOUVELLE DEMANDE",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SCHEDULE":"HORAIRES :",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-TELEPHONE":"TÉLÉPHONE",
    "FORM.ALL.BACK":"Retour au tableau de bord",
}

const UserPostalCode = [
    '5640 AD-ARMO-PM',
    '5400 AD-ARMO-PON',
    '5600 AD-ARMO-VR'
]

const knowProductReferenceproducts = [
    '',
    'Fixed or variable opening wrench',
    'Pawls',
    'Sockets',
    'Screwdrivers',
    'Male wrenchs',
    'Pliers / Vise pliers',
    'Measuring / Tracing',
    'Hammers / Strike',
    'Sawing / Drilling / Cutting',
    'Extraction',
    'Other',
]

const typeCaseList = [
    'List1',
    'List12',
    'Casse',
    'ROULIER',
]


function useWindowSize(){
    const[windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        function handleResize(){
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize',handleResize);
        handleResize()
        return () => window.removeEventListener('resize',handleResize)
    },[])
    return windowSize
    }
    
function WarrantyPage (){
    const {width,height} = useWindowSize();
    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    let history = useHistory();
    const date = new Date()
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate()
    const hour = date.getHours()
    const Minutes = date.getMinutes()
    
    const TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`

    const {version, distributorID,setdistributorID}= useContext(AppConext);
    const {reasonExchangeID,setReasonExchangeID,inputProductSku,setInputProductSku,productID,setProductID}= useContext(AppConext);
    
    
    const { sub } = profile

    


    const [selectedProfession, setSelectedProfession] = useState('')
    // const [selectedProductReference,setSelectedProductReference ] = useState(productReferenceproducts[0])
    const [selectedProductReference,setSelectedProductReference ] = useState('Tapez la référence')
    const [selectedPostalCodeFinal,setSelectedPostalCodeFinal ] = useState('Renseignez un code postal ou *')
    const [selectedProductReferenceByName,setSelectedProductReferenceByName ] = useState('Type de produit (exemple : clé à molette)')
    const [selectedTypeCase,setselectedTypeCase ] = useState('Seletionner')
    const [inputDistributor,setInputDistributor ] = useState('')
    // const [productReferenceID,setProductReferenceID ] = useState('')
    
   
    const {details, setDetails} = useContext(AppConext)
    var totalCaracters = 600 - details.length
    useEffect(() => {
        setProductID('')
        setReasonExchangeID('')
        setdistributorID('')
        setDetails('')
    }, [])
    //Upload Photos 
    const [currentReceiptImage1, setCurrentReceiptImage1]=useState('')
    const [fileNameImage1, setFileNameImage1] = useState('')
    const [currentReceiptImage2, setCurrentReceiptImage2]=useState('')
    const [fileNameImage2, setFileNameImage2] = useState('')
    const [currentReceiptImage3, setCurrentReceiptImage3]=useState('')
    const [fileNameImage3, setFileNameImage3] = useState('')
    const [currentReceiptImage4, setCurrentReceiptImage4]=useState('')
    const [fileNameImage4, setFileNameImage4] = useState('')
    var [pageStatus, setPageStatus] = useState(1);

      //Call api
      const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Success create',
        })
    }
    //API STATE
    const [productsArray, setProductsArray] = useState([]);
    const [productsReferenceArray, setProductsReferenceArray] = useState([]);
    const [distributorssrray, setDistributorsArray] = useState([]);
    const [distributorsIdArray, setDistributorsIdArray] = useState([]);
    const [userArray, setUserArray] = useState([]);
    const [reasonForExchangesArray, setReasonForExchangesArray] = useState([]);

 
    const [loading, setLoading] = useState(false);
    const [loadingLast, setLoadingLast] = useState(false);
    useEffect(() => {
        setLoading(!loading)
        try{
            getAllReasonExchange()
            getUser()
            getProductsReference()
        }catch(error){

        }finally{
            setLoading(false)
        }
    }, [])


    
    //!-------------------USER------------------------
    
    const getUser = async () =>{
        setLoading(!loading)
        try{
            const response = getAllUsersById(sub);  
            const data = (await response).data;
            setUserArray(data)
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }
        //!-------------------PRODUCTS------------------------
    const getProductsReference = async () =>{
        setLoading(!loading)
        try{
            const response = getAllProductsByReferenceDD2();  
            const data = (await response).data;
            setProductsReferenceArray(data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    reference : items.type,
                    id : items._id
                };
                return(rObj)
            }))
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }
    const getProducts = async () =>{
        setLoading(!loading)
        try{
            const response = getAllProductsByReference(inputProductSku);  
            const data = (await response).data;
            // setProductsArray(data)
            setProductsArray(data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    reference : items.reference,
                    id : items._id
                };
                return(rObj)
            }))
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }

    const getAllProduct = async () =>{
        setLoading(!loading)
        try{
            const response = getAllProducts();  
            const data = (await response).data;
            // setProductsArray(data)
            setProductsArray(data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    reference : items.reference,
                    id : items._id
                };
                return(rObj)
            }))
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }
    const handleChange = (value)=>{
        setInputProductSku(value)
        if(value.slice(0,1)!== "*"){
            if(value.length >= 2){
                getProducts(value)
            }
        }
        if(value === "*"){
            getAllProduct(value)
        }
    }

     //!-------------------DISTRIBUTOR------------------------

     const getDistributor = async () =>{
        setLoading(!loading)
        try{
            const response = getAllDistributorName(inputDistributor); 
            const response2 = getAllDistributorPostalCode(inputDistributor);   
            const data = (await response).data;
            const data2 = (await response2).data;
            // setProductsArray(data)
            const array1 = (data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    name : items.name,
                    id : items.id
                };
                return(rObj)
            }))
            const array2 =(data2.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    name : items.address?.postal_code,
                    id : items.id
                };
                return(rObj)
            }))
            const array3 = array1.concat(array2)
            setDistributorsArray(array3)

            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }

    const getAllDistributor = async () =>{
        setLoading(!loading)
        try{
            const response = getAllDistributors(inputDistributor); 

            const data = (await response).data;

            // setProductsArray(data)
            const array1 = (data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    name : items.name,
                    id : items.id
                };
                return(rObj)
            }))
           
            setDistributorsArray(array1)

            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }

    const [loadinDist,setLoadingDist] = useState(false)
    const getDistributorID = async () =>{
        setLoadingDist(!loadinDist)
        try{
            const response = getDistributorsById(distributorID); 
            const data = (await response).data; 
            setDistributorsIdArray(data)
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoadingDist(false)
        }  
    }


    const handleChangeDist = (value)=>{
        setInputDistributor(value)
        if(value.slice(0,1)!== "*"){
            if(value.length >= 2){
                getDistributor(value)
            }
        }
        if(value === "*"){
            getAllDistributor()
        }
    }
   
    

    
    //? Se agrega el id segun la referencia al producto
   



    //* MAPEO DE REASN FOR EXCHANGE EN OBJETO PARA DROPDOWN

    //!------------------------------EXCHANGES------------------------------------
    const successUpdated= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Exchange has been updated',
            confirmButtonText: 'OK',
        }).then((result) => {
        })
    }
    const getAllReasonExchange = async () =>{
        try{
            const response = getAllReasonExchanges();  
            const data = (await response).data;
            let filteredTableByDisplayOrder = data.result.items.sort((a, b) => (parseInt(a.display_order) > parseInt(b.display_order)) ? 1 : -1);
            // setProductsArray(data)
            setReasonForExchangesArray(filteredTableByDisplayOrder.map(function(items){
                var rObj = {
                    label : items.label,
                    refused : items.is_for_change,
                    id : items.id
                };
                return(rObj)
            }))
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
        }  
    }

    
//   console.log('IDPRODUCTReference',productReference)

    const [createExchangesID,setCreateExchangesID] = useState('')
    const createNewExchanges = async () =>{
        setLoadingLast(!loadingLast)
        try{
            const response = createExchange(
                TodayDate,
                userArray.result?.customer_data?._id,
                productID,
                reasonExchangeID,
                details,
                "img"
            );  
            alertSuccess()
            const data = (await response).data;
            setCreateExchangesID(data.result._id)
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setPageStatus(6)
            setLoadingLast(false)
        }  
    }

    const updateToSpecificStatus = async (button) =>{
        if(distributorID){
            try{
                await updateExchangesInfo()
                const response = updateExchangesStatus(createExchangesID,'Submitted');
                const data = (await response).data;
                successUpdated()
                return(data)
            }catch(error){
                console.log("error updated")
                console.log(error)
                alertWrongGet()
            }finally{
                setLoading(false)
                console.log("end")
                if(button){
                    history.push('/')
                }else{
                    
                    history.go(0)
                } 
            } 
        }
    }


    //UPDATE ALL INFO
    const updateExchangesInfo= async () =>{
        if(distributorID){
            setLoading(!loading)
            try{
                const response = updateExchanges(
                    createExchangesID,
                    userArray.result?.customer_data?._id,
                    distributorID,
                    productID,
                    reasonExchangeID);  
                const data = (await response).data;
                // successUpdated()
                return(data)
            }catch(error){
                console.log("error update info")
                console.log(error)
                alertWrongGet()
            }finally{
                setLoading(false)
                console.log("end")
            }  
        }
    }

        const arrowLeft="< "

        //FILE ATTACHEMENT 
        const getBase64 = (file) => {
            return new Promise((resolve) => {
                let fileInfo;
                let baseURL = '';
              // Make new FileReader
                let reader = new FileReader();
              // Convert the file to base64 text
                reader.readAsDataURL(file);
              // on reader load somthing...
                reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
                };
            });
        };
        
        const handleChangeStatus = async(meta, status,files) => {
            if(status==='done'){
                const base64File = await getBase64(files[0].file)
                setCurrentReceiptImage1(base64File)
                setFileNameImage1(files[0].file.name)
            }
        }
        const handleChangeStatusImage2 = async(meta, status,files) => {
            if(status==='done'){
                const base64File = await getBase64(files[0].file)
                setCurrentReceiptImage2(base64File)
                setFileNameImage2(files[0].file.name)
            }
        }
        const handleChangeStatusImage3 = async(meta, status,files) => {
            if(status==='done'){
                const base64File = await getBase64(files[0].file)
                setCurrentReceiptImage3(base64File)
                setFileNameImage3(files[0].file.name)
            }
        }
        const handleChangeStatusImage4 = async(meta, status,files) => {
            if(status==='done'){
                const base64File = await getBase64(files[0].file)
                setCurrentReceiptImage4(base64File)
                setFileNameImage4(files[0].file.name)
            }
        }

        const renderInfoPage = ()=>{
            return(
                <div style={{backgroundColor:'#FFF',borderRadius:'10px'}}>
                    <div className="row">
                        <div className="col-1 col-lg-2"></div>
                        <div className="col-10 col-lg-8">
                            <h3 className="h3-stl-warranty pt-5"><strong>{content['FORM-NEW-WARRANTY-INFO.STEP-BOX-TITLE']} </strong></h3>
                            <div className="line-loggin pb-5" style={{display:'flex',justifyContent:'center'}}></div>
                            <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-INFO.STEP-INFO-TITLE-1']}</strong></h5>    
                            <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-INFO.STEP-INFO-TEXT-1']}</h5>
                            <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-INFO.STEP-INFO-TITLE-2']}</strong></h5>
                            <h5 className="h5-stl-warranty Left pb-3">{content['FORM-NEW-WARRANTY-INFO.STEP-INFO-TEXT-2']}</h5>
                            <h5 className="h5-stl-warranty Left pb-5"><i>{content['FORM-NEW-WARRANTY-INFO.STEP-INFO-DETAILS']}</i></h5>
                            <div className="row">
                                <div className="col-2 col-lg-10"></div>
                                <div className="col-8 col-lg-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-INFO.STEP-INFO-BUTTON']}
                                    onClick = {() => setPageStatus(2)}/>
                                </div>
                                <div className="col-2 col-md-0"></div>
                            </div>
                            <div className="py-5"></div>
                        </div>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    
                </div>
            );
        }

        // !------------------------------------!changes-------------------------------------

        
        const handleChangeToStep2 = () =>{
            if(productID){
                setPageStatus(3)
            }   
        }
        const handleChangeToStep4 = () =>{
            if(reasonExchangeID){
                setPageStatus(5)
            }   
        }
        const handleChangeToStep5 = () =>{
            if(details){
                setPageStatus(6)
                createNewExchanges()
            }   
        }

        //? -----------------------------------STEP 1 --------------------------------------

        const renderProductSelection = ()=>{
            return(
                <div style={{backgroundColor:'#FFF',borderRadius:'10px'}}>

                    <div className="row" style={{width:'100%'}}>
                        <div className="pt-3">
                            <TabWarranty
                            pageStatus = {pageStatus}/>
                        </div>
                        <div className="col-1 col-lg-2"></div>
                        <div className="col-10 col-lg-8">
                        <div className="py-4"></div>
                        <h5 className="h5-stl-warranty Left py-1"><strong>{content['FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-TITLE']}</strong></h5>   
                        <DropDownSKU
                        options = {productsArray}
                        selectedItem={selectedProductReference}
                        onSelect={setSelectedProductReference}
                        onChange = {(value)=>{handleChange(value)}}/>
                        <div className="py-3"></div>

                        <h5 className="h5-stl-warranty Center"><strong>{content['FORM-NEW-WARRANTY-FIRST.STEP-OR']}</strong></h5>

                        <div className="py-3"></div>   
                        <h5 className="h5-stl-warranty Left py-1"><strong>{content['FORM-NEW-WARRANTY-FIRST.STEP-INPUT-DONT-KNOW-TITLE']}</strong></h5>   
                        <DropDownProduct
                        options = {productsReferenceArray}
                        selectedItem={selectedProductReferenceByName}
                        onSelect={setSelectedProductReferenceByName}
                       />
                        <div className="py-4"></div> 
                            <div className="row">
                                <div className="col-6 col-lg-6 py-2" style={{textAlign:'left !important'}}>
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE']}
                                   
                                    onClick = {() => setPageStatus(1)}
                                    width= "120px"
                                    float = "left"/>
                                </div>
                                {/* <div className="col-0 col-lg-8"></div> */}

                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING']}
                                    onClick = {() => handleChangeToStep2()}
                                    disabled = {!productID}
                                    width= "120px"
                                    float = "right"/>
                                </div>
                                <div className="col-0 col-md-0"></div>
                            </div>
                            <div className="py-5"></div>
                        </div>
                        <div className="col-1 col-lg-2"></div>
                    </div>
                    
                </div>
            );
        }

        //Condition for renders Files

        var PhotosStatus = 1;
        var DisabledPhoto1 = false;
        var DisabledPhoto2 = true;
        var DisabledPhoto3 = true;
        var DisabledPhoto4 = true;
        if (currentReceiptImage1 != ''){
            PhotosStatus = 2;
            var DisabledPhoto2 = false;
        }
        if (currentReceiptImage2 != ''){
            PhotosStatus = 3;
            var DisabledPhoto3 = false;
        }
        if (currentReceiptImage3 != ''){
            PhotosStatus = 4;
            var DisabledPhoto4 = false;
        }

         //change of screen
        var footerBool = true;
        if (window.outerWidth < 800){
            if (window.outerWidth  > 500){
                footerBool=false;
            }else{
                footerBool=true;
            }
            
        }
     //? -----------------------------------STEP 2 --------------------------------------
        const renderPhotos= ()=>{
            return(
                <div style={{backgroundColor:'#FFF',borderRadius:'10px'}}>

                    <div className="row" style={{width:'100%'}}>
                        <div className="pt-3">
                            <TabWarranty
                            pageStatus = {pageStatus}/>
                        </div>
                        
                        <div className="col-1 col-md-2"></div>
                        <div className="col-10 col-md-8">
                            <div className="py-4"></div>
                            <div className="row">
                                
                                <div className="col-6 col-lg-2">
                                    <FileAttachement
                                    fileName={fileNameImage1}
                                    handleChangeStatus={handleChangeStatus}
                                    contentText = {content['FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-1']}
                                    disabled = {DisabledPhoto1}
                                    numberPhoto = {1}
                                    photoStatus = {PhotosStatus}
                                    />
                                </div>
                                <div className="col-6 col-lg-2">
                                    <FileAttachement
                                    fileName={fileNameImage2}
                                    handleChangeStatus={handleChangeStatusImage2}
                                    contentText =  {content['FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-2']}
                                    disabled = {DisabledPhoto2}
                                    numberPhoto = {2}
                                    photoStatus = {PhotosStatus}/>
                                </div>
                                <div className="col-6 col-lg-2">
                                    <FileAttachement
                                    fileName={fileNameImage3}
                                    handleChangeStatus={handleChangeStatusImage3}
                                    contentText = {content['FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-3']}
                                    disabled = {DisabledPhoto3}
                                    numberPhoto = {3}
                                    photoStatus = {PhotosStatus}/>

                                </div>
                                <div className="col-6 col-lg-2">
                                    <FileAttachement
                                    fileName={fileNameImage4}
                                    handleChangeStatus={handleChangeStatusImage4}
                                    contentText =  {content['FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-4']}
                                    disabled = {DisabledPhoto4}
                                    numberPhoto = {4}
                                    photoStatus = {PhotosStatus}/>
                                </div>
                                <div className="col-0 col-md-2"></div>
                            </div>
                            <div className="py-3">
                                
                            </div>
                            {PhotosStatus === 1 && 
                                <div>
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-P1-TITLE-INFO-1']}</strong></h5>    
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.P1-STEP-INFO-1']}</h5>
                                    
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-P1-TITLE-INFO-2']}</strong></h5>  
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P1-INFO-2']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P1-INFO-2.1']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P1-INFO-2.2']}</h5>

                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE-EXAMPLE']}</strong></h5>  
                                    <img style={{display:'flex', alignContent:'left'}} src={Example1}></img>
                                </div>
                            }
                            {PhotosStatus === 2 && 
                                <div>
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-P2-TITLE-INFO-1']}</strong></h5>    
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.P2-STEP-INFO-1']}</h5>
                                    
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-P2-TITLE-INFO-2']}</strong></h5>  
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2.1']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2.2']}</h5>

                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE-EXAMPLE']}</strong></h5>  
                                    <img style={{display:'flex', alignContent:'left'}} src={Example2}></img>
                                </div>
                            }
                            {PhotosStatus === 3 && 
                                <div>
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-3']}</strong></h5>    
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.P3-STEP-INFO-1']}</h5>
                                    
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-P3-TITLE-INFO-2']}</strong></h5>  
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2.1']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2.2']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P3-INFO-2.3']}</h5>

                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE-EXAMPLE']}</strong></h5>  
                                    <img style={{display:'flex', alignContent:'left'}} src={Example3}></img>
                                </div>
                            }
                            {PhotosStatus === 4 && 
                                <div>
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-4']}</strong></h5>    
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.P4-STEP-INFO-1']}</h5>
                                    
                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-SECOND.STEP-P4-TITLE-INFO-2']}</strong></h5>  
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P4-INFO-2']}</h5>
                                    <h5 className="h5-stl-warranty Left pb-2">{content['FORM-NEW-WARRANTY-SECOND.STEP-P4-INFO-2.1']}</h5>

                                    <h5 className="h5-stl-warranty Left py-3"><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE-EXAMPLE']}</strong></h5>  
                                    <img style={{display:'flex', alignContent:'left'}} src={Example4}></img>
                                </div>
                            }
                            
                
                            <div className="py-4"></div> 
                            <div className="row">
                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE']}
                                    onClick = {() => setPageStatus(2)}
                                    width= "120px"
                                    float = "left"/>
                                </div>


                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING']}
                                    onClick = {() => setPageStatus(4)}
                                    width= "120px"
                                    float = "right"/>
                                </div>
                                <div className="col-2 col-md-0"></div>
                            </div>
                            <div className="py-5"></div>
                        </div>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    
                </div>
            );
        }

        // ! ------------------------------ STEP 3 --------------------------------
        const renderTypeCasses= ()=>{
            return(
                <div style={{backgroundColor:'#FFF',borderRadius:'10px'}}>

                    <div className="row" style={{width:'100%'}}>
                        <div className="pt-3">
                            <TabWarranty
                            pageStatus = {pageStatus}/>
                        </div>
                        
                        <div className="col-1 col-md-2"></div>
                        <div className="col-10 col-md-8">
                        <div className="py-4"></div>
                            <h5 className="h5-stl-warranty Left py-1"><strong>{content['FORM-NEW-WARRANTY-THIRD.STEP-INPUT-KNOW-TITLE']}</strong></h5>   

                            <DropDownReasonEx
                            options = {reasonForExchangesArray}
                            selectedItem={selectedTypeCase}
                            onSelect={setselectedTypeCase}/>
                            <div className="py-3"></div>

                        
                            <div className="py-4"></div> 
                            <div className="row">
                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE']}
                                    onClick = {() => setPageStatus(3)}
                                    width= "120px"
                                    float = "left"/>
                                </div>


                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING']}
                                    disabled = {!reasonExchangeID}
                                    onClick = {() => handleChangeToStep4()}
                                    width= "120px"
                                    float = "right"/>
                                </div>
                                <div className="col-2 col-md-0"></div>
                            </div>
                            <div className="py-5"></div>
                        </div>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    
                </div>
            );
        }


        const formDescribe = useRef(null);

             //? -----------------------------------STEP 4 --------------------------------------

        const renderSend= ()=>{
            
            return(
                <div style={{backgroundColor:'#FFF',borderRadius:'10px'}}>

                    <div className="row" style={{width:'100%'}}>
                        <div className="pt-3">
                            <TabWarranty
                            pageStatus = {pageStatus}/>
                        </div>
                        
                        <div className="col-1 col-md-2"></div>
                        <div className="col-10 col-md-8">
                        <div className="py-4"></div>
                            <h5 className="h5-stl-warranty Left py-1"><strong>{content['FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-TITLE']}</strong></h5>   
                                <TextArea
                                    name="Code"
                                    placeholder="Descriptif du problème constaté*"
                                    style={{ height: '350px',width:'100%',padding:'15px'}}
                                    currentValue={details}
                                    handleOnInputValue={(data) => { setDetails(data) }}
                                    maxLength ={600} />
                            <h5 className="h5-stl-warranty Left py-1"><strong>{totalCaracters} {content['FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-INFO']}</strong></h5>
                            <div className="py-4"></div> 
                            <div className="row">
                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE']}
                                    onClick = {() => setPageStatus(4)}
                                    width= "120px"
                                    float = "left"/>
                                </div>

                                <div className="col-6 col-lg-6 py-2">
                                    <ButtonClean
                                    disabled = {!details}
                                    message= {content['FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING']}
                                    onClick = {() => handleChangeToStep5()}
                                    width= "120px"
                                    float = "right"/>
                                </div>
                                <div className="col-2 col-md-0"></div>
                            </div>
                            <div className="py-5"></div>
                        </div>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    
                </div>
            );
        }

             //? -----------------------------------STEP 5 --------------------------------------

        const renderFinal= ()=>{

            return(
                <div>

                    <div className="row" style={{padding:'0 10%'}}>
                        <DropDownDistributor
                        options = {distributorssrray}
                        selectedItem={selectedPostalCodeFinal}
                        onSelect={(value)=>{setSelectedPostalCodeFinal(value);getDistributorID()}}
                        onChange = {(value)=>{handleChangeDist(value)}}/>

                        <div>
                            {!loadinDist ?
                            <div>
                                {selectedPostalCodeFinal != "Renseignez un code postal ou *" && 
                                <div className="row pt-4 mt-5" style={{padding:'0 30px',backgroundColor:'#FFF', borderRadius:'20px', width:'97%', marginLeft:'1%'}}>
                                    <div className="py-3"></div>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.name}</h4>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.address?.address}</h4>
                                    <h6 className="h6-final">{content['FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SCHEDULE']} :</h6>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.address?.opening_hours}</h4>
                                    <h6 className="h6-final">{content['FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-TELEPHONE']}</h6>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.address?.phone}</h4>
                                    <div className="py-3"></div>
                                </div>}
                            </div>:
                            <div className="py-5">
                                <LoadingFacom/>
                            </div>
                            }
                        </div>
                      
                            
                            <div className=" pt-5 px-5 mx-3">
                                <ButtonClean
                                    disabled = {!distributorID}
                                    message= {content['FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SUBMIT']}
                                    onClick = {() => {updateToSpecificStatus(true);setPageStatus(4)}}
                                    width= "120px"
                                    float = "right"/>
                            </div> 
                            <div className="px-5 pt-4 mx-3">
                                <ButtonClean
                                    disabled = {!distributorID}
                                    message= {content['FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-CREATE']}
                                    onClick = {() => {updateToSpecificStatus(false);setPageStatus(4)}}
                                    width= "350px"
                                    maxWidth = {width < 450 ? "280px" : "500px" }
                                    float = "right"/>
                            </div>
                            
                    </div>
                        <div className="col-1 col-md-2"></div>
                </div>
            );
        }


        var ColorPage = false;
        if(pageStatus === 1 || pageStatus === 6){
            ColorPage = true;
        }else{
            ColorPage = false;
        }



        return (
            <div >
            
                <div className="row py-5" style={ColorPage===true ? {backgroundColor:'#000',margin:'0'} : {backgroundColor:'#1B1E1F',margin:'0'}}>
                    <Link to="/">
                        <h6 className="h6-return">{arrowLeft} {content['FORM.ALL.BACK']}</h6>
                    </Link>
                    
                    <div className="row">
                        {pageStatus === 1 && <div> 
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <h2 className="h1-stl Center pt-5 " style={{maxWidth:'1500px'}}><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE']}</strong></h2>
                            </div>
                            <h5 className="h5-stl-main Center py-3 ">{content['FORM-NEW-WARRANTY-INFO.STEP-TITLE']}</h5></div>
                            }
                        {pageStatus === 2 && <div style={{display:'flex',justifyContent:'center'}}> 
                            <h2 style={{maxWidth:'1400px'}} className="h1-stl Center pt-5 "><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE']}</strong></h2>
                            </div>}
                        {pageStatus === 3 && <div style={{display:'flex',justifyContent:'center'}}> 
                            <h2 style={{maxWidth:'1400px'}} className="h1-stl Center pt-5 "><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE']}</strong></h2>
                            </div>}
                        {pageStatus === 4 && <div style={{display:'flex',justifyContent:'center'}}> 
                            <h2 style={{maxWidth:'1400px'}} className="h1-stl Center pt-5 "><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE']}</strong></h2>
                            </div>}
                        {pageStatus === 5 && <div style={{display:'flex',justifyContent:'center'}}> 
                            <h2 style={{maxWidth:'1400px'}} className="h1-stl Center pt-5 "><strong>{content['FORM-NEW-WARRANTY-ALL-TITLE']}</strong></h2>
                            </div>}
                        {pageStatus === 6 && <div> 
                            <h2 className="h1-stl Center pt-5 "><strong>{content['FORM-NEW-WARRANTY-FINAL.STEP-TITLE']}</strong></h2>
                            <h5 className="h5-stl-main Center pt-4">{content['FORM-NEW-WARRANTY-FINAL.STEP-INFO']}</h5>
                            <h5 className="h5-stl-main Center" style={{padding:'0px 10%'}}>{content['FORM-NEW-WARRANTY-FINAL.STEP-INFO-2']} </h5>
                            </div>}
                        
                        
                        <div className="col-0 col-lg-2"></div>
                        <div className="col-12 col-lg-8 px-3" style={{textAlign:'center'}}>
                            <div className="my-5">
                                {pageStatus === 1 && renderInfoPage()}
                                {pageStatus === 2 && renderProductSelection()}
                                {pageStatus === 3 && renderPhotos()}
                                {pageStatus === 4 && renderTypeCasses()}
                                {pageStatus === 5 && renderSend()}
        
                            </div>
                        </div>
                        <div className="col-1 col-md-2"></div>
                    </div>
                    {!loadingLast ?
                        <div>
                            {pageStatus === 6 && renderFinal()}
                        </div>
                        :
                        <LoadingFacom/>
                    }
                </div>

                <LoadingPag
                open = {loading}/>
                
            </div>

        );
}   

export default WarrantyPage;