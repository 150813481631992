import React, { useState, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import '../../constants/global.css'
import './styles/buttonMain.css'

function ButtonLoggout(props){
    return (
        <div>
            <button type="button" onClick={props.onClick} style = {{width:`${props.width}`,float:`${props.float}`}} className="btn-white pt-2"><strong>{props.message}</strong></button>
        </div>
        );
};

export default ButtonLoggout;