/**
 *
 * @param {contents} items saved on redux
 * @param {string} language language selected by user
 * @param {any} titleObjects template of component
 * @returns
 */
 const processItems = (items, language, titleObjects) => {
  //Create an array from key of component template
  const keyTitles = Object.keys(titleObjects);
  //Iterate this array and extract language variant from contents
  for (let i = 0; i < keyTitles.length; i++) {
    const element = keyTitles[i];
    //Search content that match with template placeholder
    const content = items.find((item) => item.placeholder === element);
    if (content) {
      //Search language variant according user selection
      const variant = content.variants.find(
        (variant) => variant.language === language
      );
      if (variant) titleObjects[element] = variant.content;
    }
  }  
  return titleObjects;
};

export default processItems