import React, {useContext, useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'


// COMPONENTS
import LoginForm from '../components/molecules/LoginForm';
import RecoverPasswordForm from '../components/molecules/RecoverPasswordFrom';
import CreateAccount from '../components/molecules/CreateAccount';

// CSS
import '../constants/global.css'
import './styles/login.css'

//contents
import { useSelector } from 'react-redux';
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "HEADER.LOGIN-HOME.TITLE" : "MON COMPTE",
    "HEADER.LOGIN-HOME.SUB-TITLE" : "NOUVELLE APPLICATION GARANTIE À VIE FACOM",
    "INFO-LIST.LOGIN-HOME-TITLE" : "En vous connectant, vous aurez accès à:",
    "INFO-LIST.LOGIN-HOME.FIRST-ITEM" : "Conditions de garantie pour chaque produit",
    "INFO-LIST.LOGIN-HOME.SECOND-ITEM" : "Prise en charge de vos retours de produit",
    "INFO-LIST.LOGIN-HOME.THIRD-ITEM" : "Suivi du traitement de mes articles échangés",
    "INFO-LIST.LOGIN-HOME.FOURTH-ITEM" : "INFO-LIST.LOGIN-HOME.FOURTH-ITEM"
}

function LoginPage (){

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

        return (
            <div style={{margin:'0',padding:'0'}}>
                <div className="row Background_Gray pt-5 " style={{margin:'0',paddingRight:'0'}}>
                    <h2 className="h1-stl Center pt-5 "><strong>{content['HEADER.LOGIN-HOME.TITLE']}</strong></h2>
                    <h3 className="h3-stl Center py-3 ">{content['HEADER.LOGIN-HOME.SUB-TITLE']}</h3>
                    <div className="row mar_responsive">
                        <div className="col-0 col-md-2"></div>
                        <div className="col-12 col-lg-4 pt-5">
                            <LoginForm/>
                            <div className="py-4"></div>
                            <RecoverPasswordForm/>
                        </div>
                        {/* <div className="col-0 col-md-1"></div> */}
                        <div className="col-12 col-lg-4 py-5">
                            <CreateAccount/>
                        </div>
                        <div className="col-0 col-md-2"></div>
                    </div>
                    <div className="row py-5 my-3">
                        <div className="col-1 col-md-5"></div>
                        <div className="col-11 col-md-7">
                        <h5 className="h5-stl">{content['INFO-LIST.LOGIN-HOME-TITLE']}</h5>
                            <ul>
                                <li></li>
                                <li><h5 className="h5-stl py-2 advantages"><strong>{content['INFO-LIST.LOGIN-HOME.FIRST-ITEM']}</strong></h5></li>
                                <li><h5 className="h5-stl py-2 advantages"><strong>{content['INFO-LIST.LOGIN-HOME.SECOND-ITEM']}</strong></h5></li>
                                <li><h5 className="h5-stl py-2 advantages"><strong>{content['INFO-LIST.LOGIN-HOME.THIRD-ITEM']}</strong></h5></li>
                                <li><h5 className="h5-stl py-2 advantages"><strong>{content['INFO-LIST.LOGIN-HOME.FOURTH-ITEM']}</strong></h5></li>
                            </ul>
                        </div>
                        </div>
                </div>
            </div>

        );
}   

export default LoginPage;