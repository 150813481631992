import React, {useContext,useEffect,useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'


// COMPONENTS

import ButtonMain from '../components/atoms/ButtonMain';
import ButtonLoggout from '../components/atoms/ButtonLoggout';
import { Link } from 'react-router-dom';

// CSS
import '../constants/global.css'
import './styles/login.css'

import MainIcon1 from '../images/MainIcon1.svg'
import MainIcon2 from '../images/MainIcon2.svg'
import MainIcon3 from '../images/MainIcon3.svg'
import MainIcon4 from '../images/MainIcon4.svg'
import { useDispatch,useSelector} from 'react-redux'
import { setProfile,clearState } from '../redux/actions/profile_actions'

//contents
import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "WARRANTY-HOME-TITLE":	"BONJOUR",
    "WARRANTY-HOME-SUB-TITLE":	"BIENVENUE DANS VOTRE NOUVEL ESPACE FACOM GARANTIE À VIE",
    "WARRANTY-HOME-INFO-TOP":	"FACOM tient à vous rappeler que cette application est destinée à traiter uniquement les produits bénéficiant de la garantie à vie. Si vous aviez une demande d'échange en cours, acceptée par le SAV FACOM mais pas encore réalisée, qui n'apparaît plus sur l'application, rendez-vous chez votre distributeur muni des emails correspondants afin d'effectuer l'échange.",
    "WARRANTY-HOME-SELECT-NEW-WARRANTY":	"NOUVELLE DEMANDE DE PRISE SOUS GARANTIE",
    "WARRANTY-HOME-SELECT-TRACK-WARRANTY":	"SUIVRE MES DEMANDES DE PRISE DE GARANTIE",
    "WARRANTY-HOME-SUB.INFO-TOP"	:"Si vous aviez une demande d'échange en cours, acceptée par le SAV FACOM mais pas encore réalisée, qui n'apparaît plus sur l'application, rendez-vous chez votre distributeur muni des emails correspondants afin d'effectuer l'échange.",
    "WARRANTY-HOME-SELECT-INFO-WARRANTY":	"DÉTAILS DE GUARANTIES FACOM",
    "WARRANTY-HOME-SELECT-PERSONAL-INFORMATION":	"MODIFIER MES INFORMATIONS PERSONNELLES",
    "WARRANTY-HOME-BUTTON-SING-OUT":	"SE DÉCONNECTER",
}

function MainPage (){
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.profileReducer);  
    const { full_name } = profile
    const onClick = () => { 
        // dispatch(clearState())
        dispatch(setProfile({
            token: '',
            email: "",
            full_name: "",
            sub: "",
            username: "",
        }))
    }
    const {contents} = useSelector((state) => state.profileReducer); 
    
    const {language} = profile
    const [content,setContent] =  useState({})
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])
    


        return (
            <div style={{margin:'0',padding:'0'}}>
                
                <div className="row Background_Gray pt-4 px-2"  style={{margin:'0'}}>
                    <h2 className="h1-stl Center pt-5 "><strong>{content['WARRANTY-HOME-TITLE']} {full_name}</strong></h2>
                    <h3 className="h3-stl Center py-3 ">{content['WARRANTY-HOME-SUB-TITLE']}</h3>
                    <h5 className="h5-stl-main Center"><strong>{content['WARRANTY-HOME-INFO-TOP']}</strong></h5>
                    <h5 className="h5-stl-main Center" style={{padding:'0 10%'}}><strong>{content['WARRANTY-HOME-SUB.INFO-TOP']}</strong></h5>

                    <div className="row" style={{marginLeft:'0'}}>
                    <div className="py-3"></div>
                        <div className="col-0 col-lg-2"></div>
                        <div className="col-12 col-lg-2 marginMain px-2 mt-3 Center" style={{padding:'0',margin:'0'}} >
                            <Link to="/demand"> 
                                <div style={{backgroundColor:'white'}} className="py-5" >
                                    <img src={MainIcon1} style={{width:'20%'}} />
                                </div>
                                <ButtonMain
                                message = {content['WARRANTY-HOME-SELECT-NEW-WARRANTY']}
                                />
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 marginMain px-2 mt-3 Center" style={{padding:'0',margin:'0'}} >
                            <Link to="/historic"> 
                                <div style={{backgroundColor:'white'}} className="py-5" >
                                    <img src={MainIcon2} style={{width:'20%'}} />
                                </div>
                                <ButtonMain
                                message = {content['WARRANTY-HOME-SELECT-TRACK-WARRANTY']}
                                
                                />
                            </Link>
                        </div>
                        <div className="col-12 col-lg-2 marginMain px-2 mt-3 Center" style={{padding:'0',margin:'0'}} >
                            <a href="https://www.facom.fr/partage/services/Garantie%20FACOM%202016.pdf" target="_blank">
                                <div style={{backgroundColor:'white'}} className="py-5" >
                                    <img src={MainIcon3} style={{width:'20%'}} />
                                </div>
                                    <ButtonMain
                                    message = {content['WARRANTY-HOME-SELECT-INFO-WARRANTY']}

                                    />
                            </a>
                            
                        </div>
                        <div className="col-12 col-lg-2 marginMain px-2 mt-3 Center" style={{padding:'0',margin:'0'}} >
                            <Link to="/account/update"> 
                                <div style={{backgroundColor:'white'}} className="py-5" >
                                    <img src={MainIcon4} style={{width:'20%'}} />
                                </div>
                                <ButtonMain
                                message = {content['WARRANTY-HOME-SELECT-PERSONAL-INFORMATION']}
                                />
                            </Link>
                        </div>
                        <div className="col-0 col-md-2"></div>
                    </div>
                    <div className="py-3 "></div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Link to="/Login"> 
                            <ButtonLoggout
                            message = {content['WARRANTY-HOME-BUTTON-SING-OUT']}
                            width="150px"
                            onClick={()=>onClick()}/>
                        </Link>
                        
                    </div>
                    
                    <div className="py-5"></div>

                </div>
            </div>

        );
}   

export default MainPage;