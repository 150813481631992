import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { Nav} from 'react-bootstrap';
import './styles/NavMenu.css'

import youtubeIcon from '../../images/YoutubeIcon.png'
import facebookIcon from '../../images/Facebookicon.png'
 
import arrowRed from '../../images/ArrowbuttonRed.png'
import { useDispatch } from 'react-redux'
import { setProfile,clearState} from '../../redux/actions/profile_actions'
import { Link, Redirect,useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import { setContents } from '../../redux/actions/contents';
//Redux

import { useSelector } from 'react-redux';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import MenuEnglish from '../../images/MenuEnglish.png'
import MenuFrench from '../../images/MenuFrance.png'

export default function NavMenuEnd (props) {
    const history = useHistory()

    const successContent= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'language updated',
            confirmButtonText: 'Reload',
        }).then((result) => {
            history.go(0)
          })
      }
    //Redux
    const { profile } = useSelector((state) => state.profileReducer);  
    const { language } = profile

    const dispatch = useDispatch();
    const handleLanguageEnglish = () =>{
        try{
            dispatch(setContents([]))
            dispatch(setProfile({ language : "EN"}))
        }catch{

        }finally{
            successContent()
        }
    
    }
    const handleLanguageFrench= () =>{
        try{
            dispatch(setContents([]))
            dispatch(setProfile({ language : "FR"}))
        }catch{

        }finally{
            successContent()
    }
    }

     //menu fade
        const [anchorEl, setAnchorEl] = useState(null);
        const opend = (anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
    
        function copy() {
            const url = document.createElement("input");
            url.value = window.location.href;
            navigator.clipboard.writeText(url.value)
            setAnchorEl(null);
        }
    return(
        <div >
            <Nav className="justify-content-end" activeKey="/home">
                <Nav.Item>
                    <Nav.Link href="#" className="text-nav-stl ">WEB CATALOGUE</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.youtube.com/user/FacomGroup" className="text-nav-stl "><img src={youtubeIcon}></img> </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://www.facebook.com/facom.fr/" className="text-nav-stl-blue"><img src={facebookIcon}></img> </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={handleClick} className="text-nav-stl">{language} <img src={arrowRed}></img> </Nav.Link>
                </Nav.Item>
                    <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={opend}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >                          
                            <MenuItem onClick={() => handleLanguageEnglish()}  style={{ fontSize: '15px',borderBottom: 'solid 1px #e0e0e0',background: '#eeeeef',width:'150px',display:'flex', justifyContent:'center'}}>
                                <img alt="" className="languaje_brand"src = {MenuEnglish} style={language === "EN" ? {width:'30px',height:'30px',borderBottom: 'solid 1px #333'} :  {width:'25px',height:'25px'}}></img>
                            </MenuItem>
                            <MenuItem  onClick={() => handleLanguageFrench()} style={{ fontSize: '15px',borderBottom: 'solid 1px #e0e0e0',background: '#eeeeef',width:'150px',display:'flex', justifyContent:'center'}}>
                                        <img alt="" className='languaje_brand' src = {MenuFrench} style={ language === "EN" ?{width:'30px',height:'30px'}:{width:'25px',height:'25px',borderBottom: 'solid 1px #333'}}></img>
                            </MenuItem>
                        </Menu>
            </Nav>
        
        </div>
    );
}