import React, {useContext, useState,useRef,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../context/AppContext';

// COMPONENTS

import {Link,useHistory} from 'react-router-dom'
import FooterLogin from '../components/molecules/FooterLogin';
import HeaderMain from '../components/molecules/HeaderMain';
import ButtonClean from '../components/atoms/ButtonClean';
import DropDownDistributor from '../components/molecules/DropDownDistributor';
import Swal from 'sweetalert2'
// CSS
import '../constants/global.css'
import './styles/warranty.css'


import facomLogo from '../images/Facom_logo.png'
import houseIcon from '../images/house.svg'

import { getAllExchangesById} from '../api/facom_services'
import { getAllDistributorName, getAllDistributorPostalCode, getDistributorsById,updateExchanges,updateExchangesStatus, getAllDistributors} from '../api/facom_services'
import LoadingPag from '../components/atoms/LoadingPag';
import LoadingFacom from '../components/atoms/LoadingFacom';
import { useSelector,useDispatch } from 'react-redux';


//contents

import operateContent from '../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.ALL.BACK" : "Retour au tableau de bord",
    "FORM-NEW-WARRANTY-FINAL.STEP-TITLE":	"FINALISER MA DEMANDE",
    "FORM-NEW-WARRANTY-FINAL.STEP-INFO":	"Suite aux éléments que vous nous avez fournis, vous semblez être éligible à la garantie à vie FACOM. ",
    "FORM-NEW-WARRANTY-FINAL.STEP-INFO-2":	"Choisissez votre distributeur pour réception de votre nouvel outil en magasin. L'application est en cours de déploiement avec nos distributeurs partenaires FACOM, vous en trouverez un proche de chez vous très bientôt, si ce n'est pas déjà le cas. Merci pour votre patience.",
    "FORM-NEW-WARRANTY-FINAL.STEP-PLACEHOLDER":	"Renseignez un code postal ou",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SUBMIT":	"SOUMETRE",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-CREATE":	"SOUMETTRE ET CRÉER UNE NOUVELLE DEMANDE",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SCHEDULE":	"HORAIRES :",
    "FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-TELEPHONE":	"TÉLÉPHONE",
}
function useWindowSize(){
    const[windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined
    })

    useEffect(() => {
        function handleResize(){
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener('resize',handleResize);
        handleResize()
        return () => window.removeEventListener('resize',handleResize)
    },[])
    return windowSize
    }
    

function SelectDistributor (props){
    const {width,height} = useWindowSize();
    let history = useHistory();
    const {distributorID}= useContext(AppConext);

    const {contents} = useSelector((state) => state.profileReducer); 
        const {profile} = useSelector((state) => state.profileReducer); 
        const {language} = profile
        const [content,setContent] =  useState({})
        useEffect(() => {
            setContent(operateContent(contents,language,Template))
        }, [])

    const [selectedPostalCodeFinal,setSelectedPostalCodeFinal ] = useState('Renseignez un code postal ou *')
    const [inputDistributor,setInputDistributor ] = useState('')

  

    // const [productReferenceID,setProductReferenceID ] = useState('')

    var [pageStatus, setPageStatus] = useState(1);

      //Call api
      const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Success create',
        })
    }
    //API STATE
    
    const [distributorssrray, setDistributorsArray] = useState([]);
    const [distributorsIdArray, setDistributorsIdArray] = useState([]);
    const [productsID, setProductID] = useState([]);
    const [customerID, setCustomerID] = useState([]);
    const [reasonForExchangesID, setReasonForExchangesID] = useState([]);
    const [exchangesArray, setExchangesArray] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingLast, setLoadingLast] = useState(false);
    const [loadingEx, setLoadingEx] = useState(false);

    useEffect(() => {
        getExchangesID()
    }, [])

    const getExchangesID = async () =>{
        setLoadingEx(!loadingEx)
        try{
            const response = getAllExchangesById(props.id); 
            const data = (await response).data; 
            setExchangesArray(data)
            setProductID(data.result.product_id)
            setCustomerID(data.result.customer_id)
            setReasonForExchangesID(data.result.exchange_reason_id)
        }catch(error){
            console.log("error get exchange")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoadingEx(false)
        }  
    }

       //!-------------------DISTRIBUTOR------------------------

        
     const getDistributor = async () =>{
        setLoading(!loading)
        try{
            const response = getAllDistributorName(inputDistributor); 
            const response2 = getAllDistributorPostalCode(inputDistributor);   
            const data = (await response).data;
            const data2 = (await response2).data;
            // setProductsArray(data)
            const array1 = (data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    name : items.name,
                    id : items.id
                };
                return(rObj)
            }))
            const array2 =(data2.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    name : items.address?.postal_code,
                    id : items.id
                };
                return(rObj)
            }))
            const array3 = array1.concat(array2)
            setDistributorsArray(array3)

            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }

    const getAllDistributor = async () =>{
        setLoading(!loading)
        try{
            const response = getAllDistributors(inputDistributor); 

            const data = (await response).data;

            // setProductsArray(data)
            const array1 = (data.result.items.map(function(items){
                // return(items.reference)
                var rObj = {
                    name : items.name,
                    id : items.id
                };
                return(rObj)
            }))
           
            setDistributorsArray(array1)

            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoading(false)
        }  
    }

    const [loadinDist,setLoadingDist] = useState(false)
    const getDistributorID = async () =>{
        setLoadingDist(!loadinDist)
        try{
            const response = getDistributorsById(distributorID); 
            const data = (await response).data;  
            setDistributorsIdArray(data)
            return(data)
        }catch(error){
            console.log("error get products")
            console.log(error)
            alertWrongGet()
        }finally{
            console.log("end")
            setLoadingDist(false)
        }  
    }

    const handleChangeDist = (value)=>{
        setInputDistributor(value)
        if(value.slice(0,1)!== "*"){
            if(value.length >= 2){
                getDistributor(value)
            }
        }
        if(value === "*"){
            getAllDistributor()
        }
    }

    //* MAPEO DE REASN FOR EXCHANGE EN OBJETO PARA DROPDOWN

    //!------------------------------EXCHANGES------------------------------------
    const successUpdated= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Exchange has been updated',
            confirmButtonText: 'OK',
        }).then((result) => {
        })
    }

    const updateToSpecificStatus = async (button) =>{
        try{
            await updateExchangesInfo()
            const response = updateExchangesStatus(props.id,'Submitted');
            const data = (await response).data;
            successUpdated()
            return(data)
        }catch(error){
            console.log("error updated")
            console.log(error)
            alertWrongGet()
        }finally{
            setLoading(false)
            console.log("end")
            if(button){
                history.push('/')
            }else{
                
                history.push('/demand')
            } 
        } 
    }


    //UPDATE ALL INFO
    const updateExchangesInfo= async () =>{
        setLoading(!loading)
        try{
            const response = updateExchanges(
                props.id,
                customerID,
                distributorID,
                productsID,
                reasonForExchangesID);  
            const data = (await response).data;
            // successUpdated()
            return(data)
        }catch(error){
            console.log("error update info")
            console.log(error)
            alertWrongGet()
        }finally{
            setLoading(false)
            console.log("end")
        }  
    }

        const arrowLeft="< "

         //? -----------------------------------STEP 5 --------------------------------------

        const renderFinal= ()=>{

            return(
                <div>

                    
                    <div className="row" style={{padding:'0 10%'}}>
                        <DropDownDistributor
                        options = {distributorssrray}
                        selectedItem={selectedPostalCodeFinal}
                        onSelect={(value)=>{setSelectedPostalCodeFinal(value);getDistributorID()}}
                        onChange = {(value)=>{handleChangeDist(value)}}/>

                        <div>
                            {!loadinDist ?
                            <div>
                                {selectedPostalCodeFinal != "Renseignez un code postal ou *" && 
                                <div className="row pt-4 mt-5" style={{padding:'0 30px',backgroundColor:'#FFF', borderRadius:'20px', width:'97%', marginLeft:'1%'}}>
                                    <div className="py-3"></div>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.name}</h4>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.address?.address}</h4>
                                    <h6 className="h6-final">{content['FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SCHEDULE']}</h6>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.address?.opening_hours}</h4>
                                    <h6 className="h6-final">{content['FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-TELEPHONE']}</h6>
                                    <h4 className="h4-stl">{distributorsIdArray?.result?.address?.phone}</h4>
                                    <div className="py-3"></div>
                                </div>}
                            </div>:
                            <div className="py-5">
                                <LoadingFacom/>
                            </div>
                            }
                        </div>
                            <div className=" pt-5 px-5 mx-3">
                                <ButtonClean
                                    disabled ={!distributorID}
                                    message="SOUMETTRE"
                                    onClick = {() => {updateToSpecificStatus(true);setPageStatus(4)}}
                                    width= "120px"
                                    float = "right"/>
                            </div> 
                            <div className="px-5 pt-4 mx-3">
                                <ButtonClean
                                disabled ={!distributorID}
                                    message="SOUMETTRE ET CRÉER UNE NOUVELLE DEMANDE"
                                    onClick = {() => {updateToSpecificStatus(false);setPageStatus(4)}}
                                    width= "350px"
                                    maxWidth = {width < 450 ? "280px" : "500px" }
                                    float = "right"/>
                            </div>
                            
                    </div>
                    
                        <div className="col-1 col-md-2"></div>
                </div>
            );
        }

        var ColorPage = false;
        if(pageStatus === 1 || pageStatus === 6){
            ColorPage = true;
        }else{
            ColorPage = false;
        }

        return (
            <div >
                
                <div className="row py-5" style={ColorPage===true ? {backgroundColor:'#000',margin:'0'} : {backgroundColor:'#1B1E1F',margin:'0'}}>
                    <Link to="/historic">
                        <h6 className="h6-return">{arrowLeft} {content['FORM.ALL.BACK']}</h6>
                    </Link>
                    
                    {!loadingEx ? 
                        <div>
                            <div className="row pb-5">
                                <div> 
                                    <h2 className="h1-stl Center pt-5 "><strong>{content['FORM-NEW-WARRANTY-FINAL.STEP-TITLE']}</strong></h2>
                                    <h5 className="h5-stl-main Center pt-4">{content['FORM-NEW-WARRANTY-FINAL.STEP-INFO']}</h5>
                                    <h5 className="h5-stl-main Center" style={{padding:'0px 10%'}}>{content['FORM-NEW-WARRANTY-FINAL.STEP-INFO-2']} </h5>
                                </div>
                            </div>
                            {!loadingLast ?
                                <div>
                                    {renderFinal()}
                                </div>
                                :
                                <LoadingFacom/>
                            }
                        </div>
                        :
                        <div className="py-5">
                            <LoadingFacom/>
                        </div>
                    } 
                </div>

                <LoadingPag
                open = {loading}/>
                
            </div>

        );
}   

export default SelectDistributor;