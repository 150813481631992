import { Profile } from "../actions/profile_actions";
import { Contents } from "../actions/contents";
import { profileReducer } from "./profile_reducers";
import { contentsReducer } from "./contents";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const PERSIST_CONFIG = {
  key: "root",
  storage,
};

const INITIAL_STATE = {
  profile: {
    token: '',
    email: "",
    full_name: "",
    sub: "",
    username: "",
    language:"FR",
  },
  contents: [],
};

const REDUCERS = {
  profileReducer: persistReducer(PERSIST_CONFIG, profileReducer),
  contentsReducer: persistReducer(PERSIST_CONFIG, contentsReducer),
};

export { INITIAL_STATE, PERSIST_CONFIG };
export default REDUCERS;
