import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import AppConext from '../../context/AppContext';

import '../../constants/global.css'
import './style/loginForm.css'
import '../molecules/style/loginForm.css'

import Button from '../atoms/Button';
import FormInitialUser from '../atoms/FormInitialUser';
import FormLastUser from '../atoms/FormLastUser';
import FormParticularUser from '../atoms/FormParticularUser';
import FormProfessionalUser from '../atoms/FormProfessionalUser';
import DropDownWhite from '../atoms/DropDownWhite';
import Form from 'react-bootstrap/Form'
import Swal from 'sweetalert2'
import {decodeJwtToken} from '../atoms/decode'
import { createNewCustomerProfessional, createNewCustomerPersonal } from '../../api/facom_services'
import { useDispatch } from 'react-redux'
import { setProfile,clearState } from '../../redux/actions/profile_actions'
import { setContents } from '../../redux/actions/contents';
import { usersLogin, getAllUsersById } from '../../api/facom_services' 
import { useHistory } from 'react-router-dom'
//contents
import { useSelector } from 'react-redux';
import operateContent from '../../frameworks_and_drivers/helpers/operate_contents';
let Template = {
    "FORM.REGISTER-PROFESSIONAL.FORM-TITLE" : "CRÉER UN COMPTE",
    "FORM.REGISTER-PROFESSIONAL.JOB" : "Métier",
    "FORM.ALL.INFORMATION" : "J'accepte de recevoir les informations et offres commerciales de FACOM et de ses partenaires",
    "FORM.REGISTER-PROFESSIONAL.FORM-TITLE" : "CRÉER UN COMPTE",
    "FORM.ALL.CONDITIONS" : 	"J'accepte les",
    "FORM.ALL.LINK" : 	"Conditions Générales d'Utilisation",
    "FORM.ALL.CONDITIONS-TWO" : 	"et je confirme que j'ai lu et compris la",
    "FORM.ALL.CONDITIONS-lLINK.TWO" : 	"Politique de Confidentialité.",

}

const options = [
    'Professionnel Industrie',
    'Professionnel Auto-Moto',
    "Artisan rénovation d'extérieur",
    "Artisan rénovation d'intérieur",
    "Artisan carrossier",
    "Artisan charpentier",
    "Artisan chaudronnier",
    "Artisan couvreur",
    "Electricien",
    "Artisan ferronnier",
    "Artisan maçon / plaquiste",
    "Artisan menuisier",
    "Artisan outilleur",
    "Artisan ouvrier en construction / bâtiment",
    "Artisan peintre",
    "Plombierr",
    "Artisan serrurier",
    "Artisan soudeur",
    "Artisan taille de pierre",
    "Artisan technicien électricien",
    "Artisan tourneur-fraiseur",
    "Artisan autre",
    "Autres métiers",
]
function ProfessionalForm(props){

    let history = useHistory();

    const { zipCode,siretCode, address, city,firstName, lastName, email, mobilePhone, isValid, isValidSamePass,isValidSiret } = useContext(AppConext)
    const { createPassword, setCreatePassword,createPasswordConfirm, setCreatePasswordConfirm } = useContext(AppConext)
    
    const [selectedProfession, setSelectedProfession] = useState(options[0])
    const [checkConditions, setcheckConditions] = useState(false)
    const [checkAcceptInfo, setcheckAcceptInfo] = useState(false)

    const {contents} = useSelector((state) => state.profileReducer); 
    const {profile} = useSelector((state) => state.profileReducer); 
    const {language} = profile
    
    const [content,setContent] =  useState({})
    const dispatch = useDispatch();
    useEffect(() => {
        setContent(operateContent(contents,language,Template))
    }, [])

    
    const alertSuccess= ()=>{
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Success create',
        })
    }
    const alertWrongGet = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something has gone wrong please try again later!',
        })
    }

    const alertConditions = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You must accept the terms and conditions',
        })
    }
    const alertPassword = ()=>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Password must match',
        })
    }

    const alertFillTheFields = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'PLEASE FILL MANDATORY FIELDS',
        })
    }
    const createUserProfessional = async () =>{
        if (isValid && isValidSamePass && isValidSiret){
            if(firstName && lastName && email && mobilePhone && zipCode && siretCode && selectedProfession && createPassword){
                if(createPassword === createPasswordConfirm){
                    if(checkConditions){
                        
                        try{
                            const response = createNewCustomerProfessional(
                                "Artisan",
                                firstName,
                                lastName,
                                email,
                                mobilePhone,
                                zipCode,
                                siretCode,
                                selectedProfession,
                                createPassword,
                                checkAcceptInfo,
                                checkConditions,
                            );  
                            const data = (await response).data;
                            alertSuccess()
                            return(data)
                        }catch(error){
                            console.log("error create User")
                            console.log(error)
                            alertWrongGet()
                        }finally{
                            console.log("end")
                            try{
                                const response = usersLogin(email,createPassword);  
                                const data = (await response).data;
                                const userDec =  decodeJwtToken(data.result?.token,{complete:true})
                                
                                if(userDec){
                                    dispatch(setProfile({ token : data.result?.token}))
                                    dispatch(setProfile({
                                        email: userDec.payload.email,
                                        full_name: userDec.payload.full_name,
                                        sub: userDec.payload.sub,
                                        username: userDec.payload.username,
                                    }))
                                    //Languaje
                                    const response = getAllUsersById(userDec.payload.sub);  
                                    const datauser = (await response).data;
                                    const activeLanguaje = `${datauser?.result?.users_settings?.lenguage_active}`.toLowerCase()
                                    let languageACT = 'FR' 
                                    if(activeLanguaje === 'english'){
                                        languageACT = 'EN'
                                    }
                                    if(activeLanguaje === 'french'){
                                        languageACT = 'FR'
                                    }
                                    dispatch(setContents([]))
                                    dispatch(setProfile({ language : languageACT}))
                                }
                                return(data)
                            }catch(error){
                                    console.log("error get customer")
                                    console.log(error)
                                    alertWrongGet(error.response?.data?.message)
                                    dispatch(clearState())
                                    
                            }finally{
                                history.push('/')
                                console.log("end")
                                window.location.reload()
                            }  
                        }  
                    }else{
                        alertConditions()
                    }
                }else{
                    alertPassword()
                }        
            }else{
                alertFillTheFields()
            }
        }
    }

    const createUserParticular = async () =>{
        if (isValid && isValidSamePass){
            if(firstName && lastName && email && mobilePhone && address && zipCode && city && createPassword){
                if(createPassword === createPasswordConfirm){
                    if(checkConditions){
                        try{
                            const response = createNewCustomerPersonal(
                                "Individual",
                                firstName,
                                lastName,
                                email,
                                mobilePhone,
                                address,
                                zipCode,
                                city,
                                createPassword,
                                checkAcceptInfo,
                                checkConditions,
                            );  
                            const data = (await response).data;
                            alertSuccess()
                            return(data)
                        }catch(error){
                            console.log("error create User")
                            console.log(error)
                            alertWrongGet()
                        }finally{
                            console.log("end")
                            try{
                                const response = usersLogin(email,createPassword);  
                                const data = (await response).data;
                                const userDec =  decodeJwtToken(data.result?.token,{complete:true})
                                
                                if(userDec){
                                    dispatch(setProfile({ token : data.result?.token}))
                                    dispatch(setProfile({
                                        email: userDec.payload.email,
                                        full_name: userDec.payload.full_name,
                                        sub: userDec.payload.sub,
                                        username: userDec.payload.username,
                                    }))
                                    //Languaje
                                    const response = getAllUsersById(userDec.payload.sub);  
                                    const datauser = (await response).data;
                                    const activeLanguaje = `${datauser?.result?.users_settings?.lenguage_active}`.toLowerCase()
                                    let languageACT = 'FR' 
                                    if(activeLanguaje === 'english'){
                                        languageACT = 'EN'
                                    }
                                    if(activeLanguaje === 'french'){
                                        languageACT = 'FR'
                                    }
                                    dispatch(setContents([]))
                                    dispatch(setProfile({ language : languageACT}))
                                }
                                return(data)
                            }catch(error){
                                    console.log("error get customer")
                                    console.log(error)
                                    alertWrongGet(error.response?.data?.message)
                                    dispatch(clearState())
                                    
                            }finally{
                                history.push('/')
                                console.log("end")
                                window.location.reload()
                            }  
                        }  
                    }else{
                        alertConditions()
                    }
                }else{
                    alertPassword()
                }        
            }else{
                alertFillTheFields()
            }
        }
    }
    const renderProfessionalForm = () =>{
        return(
            <div className="row">
                {/* Falta sacar lo que se escribe*/}
                <FormProfessionalUser/>
                <div className = "col-12 col-md-4 pt-2">
                    <h4 className="h4-stl Rigth-l py-1"><strong>{content['FORM.REGISTER-PROFESSIONAL.JOB']} *</strong></h4>
                </div>
                <div className = "col-12 col-md-6 pt-2" >
                    <DropDownWhite
                    options = {options}
                    selectedItem={selectedProfession}
                    onSelect={setSelectedProfession}/>
                </div>
            </div>
        )
    }

    const renderPrarticularForm = () =>{
        return(
            <div className="row">
                <FormParticularUser/>
            </div>
        )
    }
    return (
        <div className="py-5 px-4 Background_White">
            <h2 className="h2-stl Center "><strong>{content['FORM.REGISTER-PROFESSIONAL.FORM-TITLE']}</strong></h2>
            <div className="line-loggin"></div>
            <div className="row">
                <FormInitialUser/>
            </div>
            {props.typeOfClient === "Professional" && renderProfessionalForm()}
            {props.typeOfClient === "Particular" && renderPrarticularForm()}
            <div className="row">
                <FormLastUser/>
            </div>
            
            <div className="row">
                    <div className = "col-12 col-md-4 mt-3 pt-4">
                        
                    </div>
                    <div className = "col-12 col-md-6 mt-3 pt-4">
                        <div className='mb-3' style={{display:'flex',textAlign:'justify'}}>
                            <Form.Check    
                                type='checkbox'
                                id={`default-cond-term`}
                                onChange={(event)=>{setcheckConditions(event.target.checked)}}/>
                                <div style={{paddingLeft:'10px'}}>
                                    <h6  className="h6-stl"><strong> {content['FORM.ALL.CONDITIONS']}<a className="linkTerms" href="https://test.garantiefacom.fr/cgu.html#cgu-anchor" target="_blank"> {content['FORM.ALL.LINK']}</a> {content['FORM.ALL.CONDITIONS-TWO']} <a  className="linkTerms"  href="https://test.garantiefacom.fr/cgu.html#cgu-anchor" target="_blank">{content['FORM.ALL.CONDITIONS-lLINK.TWO']} </a></strong></h6>
                                </div>
                        </div>
                        <div style={{display:'flex',textAlign:'justify'}}>
                            <Form.Check
                                type='checkbox'
                                id={`default-accept-term`}
                                onChange={(event)=>{setcheckAcceptInfo(event.target.checked)}}/>
                                <div style={{paddingLeft:'10px'}}>
                                    <h6 className="h6-stl"><strong>{content['FORM.ALL.INFORMATION']}</strong> </h6>
                                </div>
                        </div>
                    </div>
                </div>
            
                <div className="row">
                    <div className = "col-0 col-md-4"></div>
                    <div className = "col-12 col-md-6">
                        <div className="py-1"></div>
                        {props.typeOfClient === "Professional" ? 
                            <Button
                            disabled = {!isValid ? true : !isValidSamePass ? true : !isValidSiret ? true : !checkConditions ? true : false }
                            message = {`${content['FORM.REGISTER-PROFESSIONAL.FORM-TITLE']}`}
                            onClick = {()=>createUserProfessional()}
                            />
                        :
                            <Button
                            disabled = {!isValid ? true : !isValidSamePass ? true : !checkConditions ? true : false}
                            message =  {`${content['FORM.REGISTER-PROFESSIONAL.FORM-TITLE']}`}
                            onClick = {()=>createUserParticular()}/>
                        } 
                    </div>
                </div>        
        </div>

        );
};

export default ProfessionalForm;